import React from 'react';
import {Outlet} from "react-router-dom";
import Header from "../features/Header/Header";
import Footer from "../features/Footer/Footer";

const Layout = () => {
    return (
        <>
            <Header/>
            <div className="container">
                <Outlet/>
                <Footer/>
            </div>
        </>
    );
};

export default Layout;