import {setClintCart} from "../features/Cart/cartSlice";

export const verifyClientCart = async (id, cart, products, dispatch) => {
    // Verify cart quantities
    if (cart.totalCartItems) {
        let verifiedItems = cart.items.filter(item => products[item.id])

        // // LOGIC TO CHANGE QUANTITIES IN THE CART
        //  currently car is showing that product is unavailable in requested amount but dont change after client is loaded

        // verifiedItems = verifiedItems.map(item => {
        //     if (!products[item.id]) return
        //     const {quantities} = products[item.id];
        //     const {quantity} = Object.values(quantities.colors).find(el => {
        //         return el.color === item.color && el.size === item.size
        //     })
        //     const difference = quantity - item.quantity;
        //     if (difference >= 0) return item;
        //     if (difference < 0 && quantity + difference >= 0) {
        //
        //         return {
        //             ...item,
        //             quantity: item.quantity + difference
        //         }
        //     }
        // }).filter(item => item !== undefined)


        const verifiedCart = {
            items: verifiedItems,
            totalCartItems: verifiedItems.reduce((prev, curr) => prev + curr.quantity, 0)
        }
        dispatch(setClintCart({id, cart: verifiedCart}));
    } else {
        console.log("empty");
    }
}