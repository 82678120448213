import {useLocation} from "react-router-dom";

function useGetLocations() {
    let location = useLocation().pathname.split("/").filter(el => el !== "")
    const locations = location.map((el, i) => ({
            url: `/${location.slice(0, location.length - i).join('/')}`,
            name: location[location.length - (i + 1)]
        })
    )
    return locations.reverse()
}

export default useGetLocations