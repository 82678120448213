import React from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = () => {

    const location = useLocation()
    const {isLoggedIn} = useAuth()
    const content = (
        isLoggedIn
            ? <Outlet/>
            : <Navigate to="/login" state={{from: location}} replace/>
    )
    return content
};

export default RequireAuth;