export const convertToRegEx = (REG_EX) => {
    const convertToRegExp = (regexString) => {
        const match = regexString.match(/\/(.*?)\/([gimsuy]*)$/);
        return new RegExp(match[1], match[2]);
    };

    const parsedRegEx = {};
    for (const key in REG_EX) {
        if (typeof REG_EX[key] === 'object') {
            parsedRegEx[key] = {};
            for (const subKey in REG_EX[key]) {
                parsedRegEx[key][subKey] = convertToRegExp(REG_EX[key][subKey]);
            }
        } else {
            parsedRegEx[key] = convertToRegExp(REG_EX[key]);
        }
    }
    return parsedRegEx
}