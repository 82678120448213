import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";

const About = () => {
    return (
        <>
            <InnerSection>
                <div className='about__content'>
                    <div className='about__content__column about__content__column-left about__left'>
                        <h2 className="about__content__column-title"> O nas</h2>
                        <div className="about__content__column-text">
                            <p> Marka BeBrave to połączenie pasji do sportu i mody. Tuż po studiach z Projektowania
                                Ubioru
                                poznałam wspinaczkę i wiedziałam, że chcę połączyć te dwa zamiłowania.</p>
                            <p> W międzyczasie
                                rozpoczęłam naukę z wydziale Sztuki Nowych Mediów. Po kilku latach pracy z tym zawodzie,
                                podczas wyjazdu wspinaczkowego znajoma zadała wszystkim pytanie "Jeżeli teraz
                                moglibyście
                                robić w życiu to co byście najbardziej chcieli to co to by <br/> było?". I tak się
                                zaczęła
                                moja
                                droga do projektu sprzed lat. Bebrave nie stawia granic, nie poddaje się i dąży do tego
                                czego pragnie.</p>
                            <p> Dzięki tej marce mogę robić to co uwielbiam i być kreatywną. Dodatkowo jako
                                wspinaczka wiem czego potrzeba Wam.</p>

                            <p> Tym samym jestem w stanie stworzyć produkty użytkowe,
                                nietuzinkowe i takie, które będziecie nosić przez lata. Ubrania te nie są przeznaczone
                                jedynie do wspinaczki. Są idealnie do każdego innego sportu, jak joga, bieganie czy
                                fitness.
                                A i to nie wszystko! Bo nasze ubrania założysz i do pracy i na trening i na imprezę. Nic
                                nas
                                nie ogranicza! BeBrave!
                            </p>
                        </div>
                    </div>
                    <div className='about__content__column about__content__column-right'>
                        <p className="about__content__column-motto">
                            "Każdy szczyt<br/>góry jest w <br/>zasięgu ręki, jeśli <br/> tylko będziesz <br/> się
                            wspinać” <br/><span>Barry Finlay</span>
                        </p>
                    </div>
                </div>
            </InnerSection>
            <NewsLetter/>
        </>
    );
};

export default About;