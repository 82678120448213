import React, {useEffect, useState} from 'react';
import useQuery from "../../../hooks/useQuery";
import {Link, useNavigate} from "react-router-dom";
import {useGetOrdersQuery, useUpdateOrderMutation} from "../../Orders/orderApiSlice";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import AdminOrderProductList from "./AdminOrderProductList";
import useGetLocations from "../../../hooks/useGetLocations";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminEditOrderProducts = () => {
    const [formError, setFormError] = useState()
    const [err, setErr] = useState()
    const orderId = useQuery('id');
    const [order, setOrder] = useState(null)
    const navigate = useNavigate()
    const locations = useGetLocations()
    locations.splice(-2)

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetOrdersQuery("ordersList", {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities[orderId],
            ...other
        }),
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [updateOrder, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdateOrderMutation()

    useEffect(() => {
        if (isUpdateSuccess) navigate("/admin/orders")
    }, [updateResult, isUpdateSuccess]);


    useEffect(() => {
        if (isSuccess && data) {
            const products = data.products.map(el => ({
                ...el,
                availableQuantity: 0,
                change: 0
            }))
            setOrder({
                number: data.number,
                id: data.id,
                client: data.client,
                payment: data.payment,
                products,
                productsChange: true
            })
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isUpdateError) setErr(getErrorMessage(updateError));
    }, [error, isError, updateError, isUpdateError]);

    const clearError = () => {
        setErr(null);
    };


    const onSaveOrderClicked = async (e) => {
        e.preventDefault()

        if (!order.products.length) {
            setFormError("Zamówienie musi mieć przynajmniej jeden produkt.")
            return
        }


        try {
            await updateOrder(order).unwrap()
        } catch (err) {
            setErr(getErrorMessage(err))
        }
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista zamówień"}
    </Link>)

    if (isLoading) return <h1>Loading...</h1>
    if (!order) return null;
    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>Zamówienie nr {order.number}</h1>
            <div className="admin__panel__section__buttons">
                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                <button
                    className=" admin__panel__section__buttons-button yellow-button"
                    onClick={onSaveOrderClicked}
                >{isUpdateLoading ? "Loading..." : "Zapisz"}
                </button>
            </div>
            <AdminOrderProductList
                payment={order.payment}
                orderProducts={order.products}
                setOrder={setOrder}
            />
        </section>
    );
};

export default AdminEditOrderProducts;