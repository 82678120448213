import CountrySelect from "../../../Orders/OrderForm/CountrySelect";
import {useGetClientQuery, useUpdateClientMutation} from "../../clientApiSlice";
import React, {useEffect, useState} from "react";
import SaveButton from "../../../../components/Reusable/SaveButton";
import ErrorPopup from "../../../../components/Reusable/ErrorPopup";
import {Link, useNavigate} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import ClientPanelHeader from "../ClientPanelHeader";
import {ReactComponent as Close} from '../../../../images/close.svg';
import {validateAddressData} from "../../../../utils/validateAddressData";
import useConfig from "../../../../hooks/useConfig";
import {getErrorMessage} from "../../../../utils/getErrorMessage";

const ShippingData = () => {
    const navigate = useNavigate()
    const {email} = useAuth()
    const [err, setErr] = useState(null);
    const [formError, setFormError] = useState("")
    const [check, setCheck] = useState(false)
    const CONFIG = useConfig()
    const [isValid, setIsValid] = useState();
    const [client, setClient] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        postCode: "",
        street: "",
        city: "",
        country: {id: 21, name: "Poland", code: "PL", language: "pl"},

    })

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetClientQuery(email, {
        skip: !email,
    });

    // RTK Query for adding a new order
    const [updateClient, {
        data: updatedClient,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError,
        error: updateError
    }] = useUpdateClientMutation();

    useEffect(() => {
        if (data) {
            setClient({
                name: data.name,
                surname: data.surname,
                email: data.email,
                phone: data.phone,
                postCode: data.addresses.postCode,
                street: data.addresses.street,
                city: data.addresses.city,
                country: data.addresses.country,
            })
        }
    }, [data, isSuccess]);


    useEffect(() => {
        if (isUpdateSuccess) navigate('/account/edit')
    }, [updatedClient, isUpdateSuccess]);

    useEffect(() => {
        if (isError) {
            const message = getErrorMessage(error)
            console.error(message);
            setErr(message)
        }
        if (isUpdateError) {
            const message = getErrorMessage(updateError)
            console.error(message);
            setErr(message)
        }
    }, [error, isError, updateError, isUpdateError]);

    // Validate form data whenever the data state changes
    useEffect(() => {
        if (client) {
            setFormError(null);
            setIsValid(validateAddressData(client, CONFIG))
        }
    }, [client]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        setClient(prev => ({...prev, [id]: value}))
    }

    const setCountry = (country) => {
        setClient(prev => ({...prev, country}))
    }

    const onSaveDataClicked = async (e) => {
        e.preventDefault()
        setCheck(true)

        if (!isValid.all) {
            setFormError("Wypełnij wszystkie pola.")
            return
        }

        const updatedClient = {
            id: data._id,
            name: client.name,
            surname: client.surname,
            email: client.email,
            phone: client.phone,
            addresses: {
                postCode: client.postCode,
                street: client.street,
                city: client.city,
                country: client.country,
            }
        }
        try {
            await updateClient(updatedClient).unwrap()
        } catch (err) {
            console.log(getErrorMessage(err));
        }
    }

    return (
        <div className="client__edit">
            <ClientPanelHeader client={data}/>
            <Link
                className="close-button"
                to={'/account/edit'}
            ><Close/></Link>
            <div className="col-18 client__panel__content">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <form
                    className="client__edit__form col-18 col-lg-9"
                    onSubmit={onSaveDataClicked}>
                    <h2 className="client__edit__form-title">ADRES DOSTAWY</h2>
                    <div className='row'>
                        <input
                            className={!check ? 'custom-input col-18' : isValid.name ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Imię'
                            type='text'
                            name='name'
                            data-id="name"
                            value={client?.name}
                            onChange={setInput}
                        />
                        <input
                            className={!check ? 'custom-input col-18' : isValid.surname ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Nazwisko'
                            type='text'
                            name='surname'
                            data-id="surname"
                            value={client?.surname}
                            onChange={setInput}

                        />
                    </div>
                    <CountrySelect
                        selected={client?.country}
                        setSelected={setCountry}
                    />
                    <input
                        className={!check ? 'custom-input col-18' : isValid.street ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                        type="text"
                        name="street"
                        data-id="street"
                        placeholder="Ulica i nr domu"
                        value={client?.street}
                        onChange={setInput}

                    />
                    <div className='row'>
                        <input
                            className={!check ? 'custom-input col-18' : isValid.postCode ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Kod pocztowy'
                            type='text'
                            name='postCode'
                            data-id="postCode"
                            value={client?.postCode}
                            onChange={setInput}
                        />
                        <input
                            className={!check ? 'custom-input col-18' : isValid.city ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Miasto'
                            type='text'
                            name='city'
                            data-id="city"
                            value={client?.city}
                            onChange={setInput}
                        />
                    </div>
                    <input
                        className={!check ? 'custom-input col-18' : isValid.phone ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                        placeholder='Telefon'
                        type='text'
                        name='phone'
                        data-id="phone"
                        value={client?.phone}
                        onChange={setInput}
                    />
                    <input
                        className={!check ? 'custom-input col-18' : isValid.email ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                        placeholder='e-mail'
                        type='text'
                        name='email'
                        data-id="email"
                        value={client?.email}
                        onChange={setInput}
                    />

                    <SaveButton
                        className="link-button"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                        errorMessage={formError}
                        text="ZAPISZ"
                        isLoadingText="ZAPISUJĘ..."
                    />
                </form>
            </div>
        </div>
    );
};

export default ShippingData;