import React, {useState} from 'react';
import ChooseProduct from "./ChooseProduct";
import NewsLetter from "../../Newsletter/NewsLetter";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";

const Products = () => {
    const [err, setErr] = useState(null);

    const clearError = () => {
        setErr(null);
    };

    const result = (
        <>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <ChooseProduct setErr={setErr}/>
            <NewsLetter/>
        </>
    )
    return result
};

export default Products;