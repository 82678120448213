import store from "../store";
import {useEffect} from "react";
import {productsApiSlice} from "../../features/Products/producsApiSlice";
import {Outlet} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {clientApiSlice} from "../../features/Clients/clientApiSlice";
import {getErrorMessage} from "../../utils/getErrorMessage";

const Prefetch = () => {
    const {email} = useAuth()

    useEffect(() => {
        const prefetchProducts = async () => {
            console.log("prefetching");
            try {
                await store.dispatch(
                    productsApiSlice.util.prefetch('getProducts', 'productsList', {force: true})
                );

                if (email) {
                    store.dispatch(
                        clientApiSlice.util.prefetch('getClient', email, {force: true})
                    )
                }

            } catch (error) {
                console.error("Failed to prefetch products:", getErrorMessage(error));
            }
        };

        prefetchProducts();
    }, []);

    return <Outlet/>;
};

export default Prefetch;
