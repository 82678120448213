// src/hooks/useSid.js
import {useEffect, useState} from 'react';

const generateCid = () => '_' + Math.random().toString(36).substr(2, 9);

const useSid = () => {
    const [sid, setSid] = useState(null);

    useEffect(() => {
        let storedSid = sessionStorage.getItem('sid');
        if (!storedSid) {
            storedSid = generateCid();
            sessionStorage.setItem('sid', storedSid);
        }
        setSid(storedSid);
    }, []);

    return sid;
};

export default useSid;
