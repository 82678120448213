import CheckBox from "../../../components/Reusable/CheckBox";
import CountrySelect from "./CountrySelect";

const DataSection = ({check, isValid, order, setOrder, title}) => {
    const setCheckBox = (e) => {
        const dataId = e.target.dataset.id
        setOrder(prev => ({...prev, [dataId]: e.target.checked}))
    }
    const setCountry = (country) => {
        setOrder(prev => ({...prev, country}))
    }

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        if (order.theSame !== undefined) {
            setOrder(prev => ({
                ...prev,
                billingAddress: {
                    ...prev.billingAddress,
                    [id]: value
                }
            }))
            return
        }
        setOrder(prev => ({...prev, [id]: value}))
    }

    return (
        <div className="form-section order__data">
            {title && <h2 className="form-subtitle">{title}</h2>}
            <CountrySelect
                selected={order?.country}
                setSelected={setCountry}
            />
            <div className="row">
                <input
                    className={!check ? 'custom-input col-18' : isValid?.isNameValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                    type="text"
                    name="name"
                    data-id="name"
                    placeholder="Imię"
                    value={order?.name}
                    onChange={setInput}
                    autoComplete="on"
                />
                <input
                    className={!check ? 'custom-input col-18' : isValid?.isSurnameValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                    type="text"
                    name="surname"
                    data-id="surname"
                    placeholder="Nazwisko"
                    value={order?.surname}
                    onChange={setInput}
                    autoComplete="on"
                />
            </div>
            <input
                className={!check ? 'custom-input col-18' : isValid?.isStreetValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                type="text"
                name="street"
                data-id="street"
                placeholder="Ulica i nr domu"
                value={order?.street}
                onChange={setInput}
            />

            <div className="row">
                <input
                    className={!check ? 'custom-input col-18' : isValid?.isPostCodeValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                    type="text"
                    name="postCode"
                    data-id="postCode"
                    placeholder="Kod pocztowy"
                    value={order?.postCode}
                    onChange={setInput}
                />
                <input
                    className={!check ? 'custom-input col-18' : isValid?.isCityValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                    type="text"
                    name="city"
                    data-id="city"
                    placeholder="Miasto"
                    value={order?.city}
                    onChange={setInput}
                />
            </div>
            <input
                className={!check ? 'custom-input col-18' : isValid?.isPhoneValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                type="text"
                name="phone"
                data-id="phone"
                placeholder="Telefon"
                value={order?.phone}
                onChange={setInput}
                autoComplete="on"
            />

            <input
                className={!check ? 'custom-input col-18' : isValid?.isNotesValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                type="text"
                name="notes"
                data-id="notes"
                placeholder="Daodatkowe informacje (opcjonalnie)"
                value={order?.notes}
                onChange={setInput}
            />

            {order?.remember !== undefined && <CheckBox
                className="remember-checkbox"
                name="remember"
                label="Zapisz te informacje, aby ich użyć następnym razem."
                checked={order?.remember}
                setChecked={setCheckBox}
            />}
            {order?.remember !== undefined && <CheckBox
                name="invoice"
                label="Chcę otrzymać fakturę VAT."
                checked={order?.invoice}
                setChecked={setCheckBox}
            />}
        </div>
    );
};

export default DataSection;