import React, {useEffect, useState} from 'react';
import InnerSection from "../../../components/Reusable/InnerSection";
import {Link, useNavigate} from "react-router-dom";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import SaveButton from "../../../components/Reusable/SaveButton";
import {ReactComponent as Close} from "../../../images/close.svg";
import {useConfirmResetPasswordMutation} from "../clientApiSlice";
import useQuery from "../../../hooks/useQuery";
import PasswordInput from "../../../components/Reusable/PasswordInput";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";


const ConfirmResetPassword = () => {
    const [err, setErr] = useState(null)
    const [formError, setFormError] = useState(null)
    const [isValid, setIsValid] = useState({newPassword: false, newPassword2: false, all: false})
    const [check, setCheck] = useState(false)
    const [data, setData] = useState({newPassword: "", newPassword2: ""})
    const navigate = useNavigate()
    const token = useQuery('token');
    const {REG_EX, CONSTANTS: {PASSWORD_REQUIREMENTS}} = useConfig()
    const [confirmResetPassword, {
        data: resetConfirmation,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useConfirmResetPasswordMutation()
    useEffect(() => {
        if (!token) {
            navigate("/")
        }
    }, [token]);

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [resetConfirmation, isSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };
    useEffect(() => {
        setFormError(null);
        const isValid = {
            newPassword: REG_EX.PASSWORD.test(data.newPassword),
            newPassword2: data.newPassword === data.newPassword2,
        }
        const all = Object.values(isValid).every(el => el === true)
        setIsValid({...isValid, all})
    }, [data]);

    const setInput = (e) => {
        setCheck(false)
        setFormError(null)
        let {value, dataset: {id}} = e.target;
        setData(prev => (
            {
                ...prev,
                [id]: value
            }))
    }

    const onResetPasswordClicked = async (e) => {
        e.preventDefault()
        setCheck(true)
        if (!isValid.newPassword) {
            setFormError(PASSWORD_REQUIREMENTS);
            return;
        }
        if (!isValid.all) {
            setFormError("Hasła są niezgodne.")
            return
        }

        try {
            await confirmResetPassword({...data, token})
        } catch (err) {
            console.log(getErrorMessage(err))
        }
    }

    return (
        <InnerSection>
            <div className="client__edit">
                <Link
                    className="close-button"
                    to={'/login'}
                ><Close/></Link>
                <div className="col-18 client__panel__content">
                    {err && <ErrorPopup message={err} onClose={clearError}/>}
                    <form
                        className="client__edit__form col-18 col-lg-9"
                        onSubmit={onResetPasswordClicked}>
                        <h2 className="client__edit__form-title">ZRESETUJ HASŁO</h2>

                        <PasswordInput
                            name="newPassword"
                            dataId="newPassword"
                            handlePwdInput={setInput}
                            isValid={isValid.newPassword}
                            check={check}
                        />

                        <PasswordInput
                            name="newPassword2"
                            dataId="newPassword2"
                            placeholder="Powtórz hasło"
                            handlePwdInput={setInput}
                            isValid={isValid.newPassword2}
                            check={check}
                        />
                        <SaveButton
                            className="link-button"
                            type="submit"
                            // disabled={isLoading}
                            // isLoading={isLoading}
                            errorMessage={formError}
                            text="WYŚLIJ"
                            isLoadingText="WYSYŁAM..."
                        />
                    </form>
                </div>
            </div>
        </InnerSection>
    );
};

export default ConfirmResetPassword;