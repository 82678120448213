import React, {useState} from 'react';
import ChooseProduct from "../Products/ChooseProduct/ChooseProduct";
import Landing from "./Landing";
import Subscribe from "../Newsletter/Subscribe";
import ErrorPopup from "../../components/Reusable/ErrorPopup";


const LandingPage = () => {
    const [err, setErr] = useState(null);

    const clearError = () => {
        setErr(null);
    };

    return (
        <>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <Landing/>
            <ChooseProduct setErr={setErr}/>
            <Subscribe/>
        </>)
};

export default LandingPage;