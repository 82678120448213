import React, {useEffect, useRef, useState} from "react";
import {useGetImagesQuery} from "../../Products/producsApiSlice";
import AdminEditPhotoColorModal from "../Products/AdminEditPhotoColorModal";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import useConfig from "../../../hooks/useConfig";

const PanelSectionPhotoEdit = ({state, setSelectedFiles, setErr, setFormError, isValid, check}) => {
    const {CONSTANTS: {NUMBER_OF_PICTURES}} = useConfig()
    const [previewURLs, setPreviewURLs] = useState(new Array(NUMBER_OF_PICTURES).fill(null));
    const [editPhotoColor, setEditPhotoColor] = useState({
        show: false,
        index: null
    })
    const uploadMultipleRef = useRef(null)
    const {id, pictures, selectedFiles} = state
    const {CONSTANTS: {NODE_ENV}} = useConfig()
    const {
        data: files,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetImagesQuery({pictures, id}, {
        skip: !id || !pictures
    });

    useEffect(() => {
        if (files && isSuccess) {
            setSelectedFiles(prev => ({
                ...prev,
                selectedFiles: files
            }));
        }
    }, [files, isSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error))
    }, [isError, error]);

    useEffect(() => {
        // if (selectedFiles.some(el => el !== null)) {
        let newPreviewURLs = Array(NUMBER_OF_PICTURES).fill(null)

        selectedFiles.forEach((file, i) => {
            if (file) {
                const url = URL.createObjectURL(file)
                if (NODE_ENV === "development") {
                    newPreviewURLs[i] = url
                } else {
                    newPreviewURLs[i] = url.replace("http:", "https:");
                }
            }
        })
        setPreviewURLs(newPreviewURLs.concat(new Array(NUMBER_OF_PICTURES - newPreviewURLs.length).fill(null)));
        // }
    }, [selectedFiles]);


    const onPhotoRemoveClicked = (e) => {
        let index = e.currentTarget?.dataset?.index;

        if (index === "multiple") {
            setSelectedFiles(prev => ({
                ...prev,
                selectedFiles: []
            }))
        } else {
            index = Number(index)
            if (typeof index !== "number" || index < 0 || !selectedFiles[index]) {
                setFormError('Wystąpił nieoczekiwany błąd, odświerz stronę.');
                console.error(`Error removing selected file.\n index: ${index}`)
                return;
            }

            setSelectedFiles(prev => {
                let newSelectedFiles = [...prev.selectedFiles]
                const numberOfFiles = newSelectedFiles.filter(el => !!el).length;
                numberOfFiles === 1 ? newSelectedFiles = [] : newSelectedFiles[index] = null
                return {
                    ...prev,
                    selectedFiles: newSelectedFiles
                }
            })
        }
    }

    const onEditPhotoColorClicked = (e) => {
        let index = e.currentTarget?.dataset?.index;
        setEditPhotoColor(prev => ({
            ...prev,
            show: !prev.show,
            index
        }))
    }

    const uploadMultiple = () => {
        uploadMultipleRef.current.click()
    }

    const handleFileChange = async (e) => {
        setFormError(null)
        const name = state.name || "general"
        let {index} = e.currentTarget.dataset;
        if (!index) {
            setFormError('Wystąpił nieoczekiwany błąd, odświerz stronę.');
            console.error(`Error saving selected file.\n index: ${index}`)
            return;
        }

        if (index && index === "multiple") {
            // Renaming files
            const files = Array.from(e.currentTarget.files).map((file, i) => {
                const newName = `${name}-general-${i}`;
                return new File([file], newName, {type: file.type});
            });

            if (files.length + selectedFiles.length > NUMBER_OF_PICTURES) {
                setFormError(`Możesz załączyć maksymalnie ${NUMBER_OF_PICTURES} zdjęć.`);
                return;
            }
            const newSelectedFiles = [...selectedFiles, ...files].slice(0, NUMBER_OF_PICTURES)

            setSelectedFiles(prev => ({
                ...prev,
                selectedFiles: newSelectedFiles,
            }));

        } else {
            index = Number(index)
            if (typeof index !== "number" || index < 0) {
                setFormError('Wystąpił nieoczekiwany błąd, odświerz stronę.');
                console.error(`Error saving selected file.\n index: ${index}`)
                return;
            }
            // Renaming files
            const files = Array.from(e.currentTarget.files).map((file) => {
                const newName = `${name}-general-${index}`;
                return new File([file], newName, {type: file.type});
            });

            setSelectedFiles(prev => {
                const newSelectedFiles = [...prev.selectedFiles]
                newSelectedFiles[index] = files[0]
                return {
                    ...prev,
                    selectedFiles: newSelectedFiles
                }
            })
        }
    };
    
    return (
        <div className="admin__panel__section__from__section photo__upload">
            <span
                className="admin__panel__section__from__section-title col-18 col-md-4 col-lg-3 col-hd-2">ZDJĘCIA</span>
            <div className="admin__panel__section__from__section-inputs col-18 col-md-14 col-lg-15 col-hd-16">
                {editPhotoColor.show ?
                    <AdminEditPhotoColorModal
                        state={state}
                        index={editPhotoColor.index}
                        setEditPhotoColor={setEditPhotoColor}
                        setSelectedFiles={setSelectedFiles}
                    />
                    : null}
                <div
                    className={!check ? 'photo__upload__preview row ' : isValid ? 'photo__upload__preview row ' : 'photo__upload__preview row  not-valid'}>
                    {previewURLs.map((url, index) => (
                        <div key={index} className="photo__upload__preview-box">
                            {url ? <> <img src={url} alt={`preview-${index}`}/>
                                    <button
                                        type="button"
                                        data-index={index}
                                        onClick={onPhotoRemoveClicked}
                                        className="remove__photo">
                                        <span className="remove__photo-decor"></span>
                                        <span className="remove__photo-decor"></span>
                                    </button>
                                    {state.kind !== "Karta podarunkowa"
                                        ? <button
                                            type="button"
                                            data-index={index}
                                            onClick={onEditPhotoColorClicked}
                                            className="edit__color"
                                            disabled={!state.name || !state.quantities.list.length}
                                        >
                                            <span className="edit__color-decor"></span>
                                            <span className="edit__color-decor"></span>
                                        </button>
                                        : null}
                                </> :
                                <input
                                    className="placeholder"
                                    type="file"
                                    data-index={index}
                                    onChange={handleFileChange}
                                    accept="image/png, image/jpeg, image/webp"
                                    disabled={state.kind !== "Karta podarunkowa" && !state.name || state.kind !== "Karta podarunkowa" && !state.quantities.list.length}
                                />
                            }
                        </div>
                    ))}
                </div>
                <div className="photo__upload__buttons">
                    <div className="photo__upload__buttons__input__group">
                        <input
                            ref={uploadMultipleRef}
                            className="file-input"
                            type="file"
                            data-index="multiple"
                            onChange={handleFileChange}
                            multiple
                            accept="image/png, image/jpeg, image/webp"
                        />
                        <button
                            type="button"
                            onClick={uploadMultiple}
                            className="input-decor admin-button"
                            disabled={state.kind !== "Karta podarunkowa" && !state.name || state.kind !== "Karta podarunkowa" && !state.quantities.list.length}
                        >Wybierz pliki
                        </button>
                    </div>

                    <button
                        className="admin-button"
                        type="button"
                        data-index="multiple"
                        onClick={onPhotoRemoveClicked}
                    >Wyczyść
                    </button>
                </div>
            </div>
        </div>
    )
};

export default PanelSectionPhotoEdit;
