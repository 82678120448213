import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {clientApiSlice} from "../Clients/clientApiSlice";

const initialState = {
    cart: JSON.parse(localStorage.getItem('shoppingCart')) || {
        totalCartItems: 0,
        allInStock: true,
        items: [],
        clientId: null,
        isOrderLoading: false
    },
    error: null
};

// Thunk to update the cart in the backend
export const updateClientCart = createAsyncThunk(
    'cart/updateClientCart',
    async (_, {getState, dispatch, rejectWithValue}) => {
        const state = getState();
        const {clientId, items, totalCartItems} = state.cart.cart;
        if (clientId) {
            const result = await dispatch(clientApiSlice.endpoints.updateClient.initiate({
                id: clientId,
                cart: {
                    totalCartItems,
                    items,
                }
            }));
            if (result.error) {
                console.error(result.error);
                return rejectWithValue(result.error)
            }
        }
    }
);

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setClintCart: (state, action) => {
            const {id, cart: {totalCartItems, items}} = action.payload
            state.cart.clientId = id
            state.cart.items = items
            state.cart.totalCartItems = totalCartItems
            id
                ? localStorage.removeItem('shoppingCart')
                : localStorage.setItem('shoppingCart', JSON.stringify({
                    clientId: id,
                    items,
                    totalCartItems
                }))
        },
        clearClientCart: (state) => {
            state.cart.clientId = null
            state.cart.items = []
            state.cart.totalCartItems = 0
        },
        addItem: (state, action) => {
            const {id, name, color, size, price, isPromotion, promotionAmount, basePrice} = action.payload;
            const index = state.cart.items.findIndex(el => el?.id === id && el?.size === size && el?.color === color);
            if (index > -1) {
                state.cart.items[index].quantity++;
            } else {
                state.cart.items.push({
                    id,
                    name,
                    size,
                    color,
                    quantity: 1,
                    price,
                    isPromotion,
                    promotionAmount,
                    basePrice
                });
            }
            state.cart.totalCartItems++;
            if (!state.cart.clientId) localStorage.setItem('shoppingCart', JSON.stringify(state.cart));
        },
        removeAllItems: (state) => {
            state.cart.totalCartItems = 0;
            state.cart.items = []
            if (!state.cart.clientId) localStorage.setItem('shoppingCart', JSON.stringify(state.cart));
        },
        removeItem: (state, action) => {
            const {id, color, size} = action.payload;
            const index = state.cart.items.findIndex(el => el?.id === id && el?.size === size && el?.color === color);

            if (index > -1) {
                if (state.cart.items[index].quantity === 1) {
                    state.cart.items.splice(index, 1);
                } else {
                    state.cart.items[index].quantity--;
                }
                state.cart.totalCartItems--;
                if (!state.cart.clientId) localStorage.setItem('shoppingCart', JSON.stringify(state.cart));
            }
        },
        deleteItem: (state, action) => {
            const {id, color, size} = action.payload;
            const index = state.cart.items.findIndex(el => el?.id === id && el?.size === size && el?.color === color);
            if (index > -1) {
                const newTotal = state.cart.totalCartItems -= state.cart.items[index].quantity
                state.cart.totalCartItems = newTotal;
                state.cart.items.splice(index, 1);
            }
            if (!state.cart.clientId) localStorage.setItem('shoppingCart', JSON.stringify(state.cart));
        },
        setIsOrderLoading: (state, action) => {
            state.cart.isOrderLoading = action.payload
        },
        setIsAllInStock: (state, action) => {
            state.cart.allInStock = action.payload
        },
    },

    extraReducers: (builder) => {
        builder.addCase(updateClientCart.fulfilled, (state) => {
            state.error = null; // Clear error on success
        });
        builder.addCase(updateClientCart.rejected, (state, action) => {
            state.error = action.payload || 'Failed to update cart'; // Set error on failure
        });
    }
});

export const {
    setClintCart,
    clearClientCart,
    addItem,
    removeItem,
    removeAllItems,
    deleteItem,
    setIsOrderLoading,
    setIsAllInStock
} = cartSlice.actions;

export const updateCartAfterAction = (action) => (dispatch, getState) => {
    dispatch(action);
    dispatch(updateClientCart());
};

export const selectAll = (state) => state.cart;
export const selectCartError = (state) => state.cart.error;
export const selectCart = (state) => state.cart.cart;


export default cartSlice.reducer;
