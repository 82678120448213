import NewsLetter from "./NewsLetter";
import useConfig from "../../hooks/useConfig";


const Subscribe = () => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const newsletter = (
        <section className='newsletter'>
            <img className='newsletter__video' src={`${SERVER_ADDRESS}/img/video.png`}/>
            <NewsLetter/>
        </section>
    )

    return newsletter;
};

export default Subscribe;