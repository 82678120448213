import React, {useEffect, useState} from 'react';
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {ReactComponent as Add} from "../../../images/add.svg";
import {useGetProductsQuery} from "../producsApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {addItem, selectAll, updateCartAfterAction} from "../../Cart/cartSlice";
import ProductViewAddedProduct from "../ProductView/ProductViewAddedProduct";
import DOMPurify from "dompurify";
import {marked} from "marked";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import useConfig from "../../../hooks/useConfig";

const GiftCartView = () => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const dispatch = useDispatch();
    const [err, setErr] = useState()
    const [state, setState] = useState({
        amount: 0,
    });
    const [addedProduct, setAddedProduct] = useState(null)
    const {error: cartError} = useSelector(selectAll)
    const {
        giftCard,
        isError,
        error
    } = useGetProductsQuery("productList", {
        selectFromResult: ({data, ...other}) => ({
            giftCard: data?.entities ? Object.values(data?.entities).find(el => el.name === "Karta podarunkowa") : undefined,
            ...other
        }),
    });

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (cartError) setErr(getErrorMessage(cartError))
    }, [error, isError, cartError]);

    const clearError = () => {
        setErr(null);
    };

    const handleSizeSelection = (e) => {
        const amount = e.target.dataset.id;
        setState(prevState => ({
            ...prevState,
            amount: Number(amount),
            error: null
        }));
    };

    const handleToggleDetail = (event) => {
        const element = state.expandedSection === event.target.name ? null : event.target.name;
        setState(prevState => ({
            ...prevState,
            expandedSection: element
        }));
    };

    const handleBuyCard = () => {
        // Check if a size is selected
        console.log(state);
        if (!state.amount) {
            setState(prevState => ({
                ...prevState,
                error: 'Wybierz kwotę karty' // Select size
            }));
            return
        }

        dispatch(updateCartAfterAction(addItem({
            id: giftCard.id,
            name: giftCard.name,
            color: "none",
            size: state.amount.toString(),
            price: state.amount,
            isPromotion: giftCard.promotion.isPromotion,
            promotionAmount: giftCard.promotion.promotionAmount,
            basePrice: giftCard.promotion.basePrice,
        })));

        setAddedProduct({
            type: "giftCard",
            image: `${SERVER_ADDRESS}/img/${giftCard.id}/${giftCard.pictures[0]}`,
            name: giftCard?.name,
            kind: null,
            color: null,
            size: null,
            amount: state.amount
        })

    };

    if (!giftCard) return null
    const amountButtons = giftCard.amounts.map(el =>
        <button key={el}
                data-id={el}
                className={state.amount === el ? "choose__size choose__size-selected" : "choose__size"}
                onClick={handleSizeSelection}
        >{el}
        </button>)
    const description = <div className="md-body product__description"
                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(giftCard.description))}}/>

    return (
        <section className='product product__giftCard row'>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            {addedProduct ? <ProductViewAddedProduct
                addedProduct={addedProduct}
                setAddedProduct={setAddedProduct}
            /> : null}
            <div className="product__pictures col-18 col-md-9">
                {giftCard.pictures.map(fileName => (
                    <img key={fileName} className='product__picture'
                         src={`${SERVER_ADDRESS}/img/${giftCard.id}/${fileName}`}
                         alt={fileName}/>
                ))}
            </div>
            <div className="product__info col-18 col-md-9">
                <div className="product__info-content">
                    <p className='product__kind'>Karta podarunkowa</p>
                    <h2 className='product__name'>Karta podarunkowa</h2>
                    {state.amount
                        ? <p className='product__price-tag'>{state.amount} PLN</p>
                        : <p className='product__price'>Wybierz kwotę</p>}
                    <div className='product__sizes'>
                        <div className='product__sizes-buttons'>
                            {amountButtons}
                        </div>
                    </div>
                    <div className="button-group">
                        <span className="error-message">{state.error}</span>
                        <button
                            className='link-button'
                            onClick={handleBuyCard}
                        >DODAJ DO KOSZYKA
                        </button>
                    </div>
                    <div className='col-12 product__detail'>
                        <h2 className='product__detail-title'>OPIS</h2>
                        <input type="checkbox" name="description" checked={state.expandedSection === "description"}
                               onChange={handleToggleDetail}/>
                        <Add className="show"/>
                        {description}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GiftCartView;