import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";
import {verifyClientCart} from "../../utils/verifyClientCart";
import {selectCart} from "../Cart/cartSlice";
import {getErrorMessage} from "../../utils/getErrorMessage";

const productsAdapter = createEntityAdapter({})
const initialState = productsAdapter.getInitialState()

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            query: (data) => {
                const params = new URLSearchParams(data?.filters).toString();
                return {
                    url: `/api/products?${params}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError
                    }
                }
            },
            // keepUnusedDataFor: 5,
            transformResponse: responseData => {
                const loadedProducts = responseData.map(product => {
                    product.id = product._id
                    return product
                });
                return productsAdapter.setAll(initialState, loadedProducts)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Product', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Product', id}))
                    ]
                } else return [{type: 'Product', id: 'LIST'}]
            },

            async onQueryStarted(arg, {dispatch, queryFulfilled, getState}) {
                try {
                    const cart = selectCart(getState());
                    const {data: {entities: products}} = await queryFulfilled;
                    const {clientId, totalCartItems, isOrderLoading} = cart

                    // Verify cart quantities after products list is loaded
                    if (totalCartItems && !isOrderLoading) return verifyClientCart(clientId, cart, products, dispatch)
                } catch (err) {
                    console.error(getErrorMessage(err));
                }
            },
        }),
        getAllProducts: builder.query({
            query: (data) => {
                return {
                    url: '/api/admin/products?',
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError
                    }
                }
            },
            // keepUnusedDataFor: 5,
            transformResponse: responseData => {
                const loadedProducts = responseData.map(product => {
                    product.id = product._id
                    return product
                });
                return productsAdapter.setAll(initialState, loadedProducts)
            },
            providesTags: (result, error, arg) => [{type: 'AdminProduct', id: 'LIST'}]
        }),
        getImages: builder.query({
            async queryFn({pictures, id}, {dispatch}) {

                if (!pictures || pictures.length === 0) {
                    return {data: []};  // Early return if there are no pictures
                }

                try {
                    const files = await Promise.all(
                        pictures.map(async (fileName) => {

                            const configResult = await dispatch(apiSlice.endpoints.getConfig.initiate());
                            if (configResult.error) {
                                throw new Error(`Failed to fetch config: ${configResult.error.message}`);
                            }
                            const {CONSTANTS: {NODE_ENV}} = configResult.data;

                            const url = new URL(window.location.href);
                            // const SERVER_ADDRESS = process.env.NODE_ENV === 'development'
                            const SERVER_ADDRESS = NODE_ENV === 'development'
                                ? `${url.protocol}//${url.hostname}:3500`
                                : `${url.protocol}//${url.hostname}`

                            const path = `${SERVER_ADDRESS}/img/${id}/${fileName}`
                            const response = await fetch(path);
                            if (!response.ok) {
                                throw new Error(`Failed to fetch image: ${fileName} - ${response.statusText}`);
                            }
                            const blob = await response.blob();
                            return new File([blob], fileName, {
                                type: blob.type,
                                lastModified: new Date(),
                            })
                        })
                    );
                    return {data: files}
                } catch (err) {
                    return {error: {message: `Failed to load images: ${err.message}`}};
                }
            },
        }),
        addNewProduct: builder.mutation({
            query: (initialProductData) => ({
                url: '/api/admin/products',
                method: 'POST',
                body: {
                    ...initialProductData
                }
            }),
            invalidatesTags: [
                {type: 'Product', id: 'LIST'}
            ]
        }),

        updateProduct: builder.mutation({
            query: (initialProductData) => ({
                url: '/api/admin/products',
                method: 'PATCH',
                body: {
                    ...initialProductData
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Product', id: 'LIST'}, {type: 'AdminProduct', id: 'LIST'}
            ]
        }),
        deleteProduct: builder.mutation({
            query: (id) => ({
                url: '/api/admin/products',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: [
                {type: 'Product', id: "LIST"}
            ]
        })
    })
})

export const {
    useGetProductsQuery,
    useGetAllProductsQuery,
    useGetImagesQuery,
    useAddNewProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
} = productsApiSlice

// returns the query object
export const selectProductsResult = productsApiSlice.endpoints.getProducts.select()

// creates memoized selector
const selectProductsData = createSelector(
    selectProductsResult,
    productsResult => productsResult.data //normalized state object with ids & entities
)

export const {
    selectAll: selectAllProducts,
    selectById: selectProductById,
    selectIds: selectProductIds
    // pass in a selector that returns the products slice of state
} = productsAdapter.getSelectors(state => selectProductsData(state) ?? initialState)