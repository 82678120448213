import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {useGetClientsQuery} from "../../Clients/clientApiSlice";
import AdminClientRow from "./AdminClientRow";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminOrdersList = () => {
    const [err, setErr] = useState()

    const {
        data: clients,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientsQuery('clientsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    let result
    if (isSuccess) {
        const {ids} = clients
        const rows = ids.map((id, i) => {
            return <AdminClientRow
                key={id}
                id={id}
                setError={setErr}
                number={i + 1}
            />
        })

        if (isSuccess) result = (
            <section className="admin__panel__section admin__clients col-18 col-lg-16">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <h1 className="admin__panel__section-title">KLIENCI</h1>
                <div className="admin__panel__section__buttons">
                    <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                </div>
                <div className="admin__panel__section__list admin__clients__list ">
                    <div className="admin__panel__section__list-item col-2 list-header">Usuń</div>
                    <div className="admin__panel__section__list-item col-4 list-header">Imię</div>
                    <div className="admin__panel__section__list-item col-4 list-header">Nazwisko</div>
                    <div className="admin__panel__section__list-item col-4 list-header">e-mail</div>
                    <div className="admin__panel__section__list-item col-2 product__kind list-header">Status</div>
                    <div className="admin__panel__section__list-item col-2 list-header">Edycja</div>
                </div>
                {rows}
            </section>
        );
        return result
    }
};

export default AdminOrdersList;