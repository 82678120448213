import React from 'react';
import CheckBox from "../../../components/Reusable/CheckBox";

const AdminEditPromotion = ({check, isValid, product, setProduct}) => {
    const setInput = (e) => {
        const value = Number(e.target.value)
        setProduct(prev => {
            const {basePrice} = prev.promotion
            return {
                ...prev,
                promotion: {
                    ...prev.promotion,
                    promotionAmount: value
                },
                price: basePrice - basePrice * (value / 100)
            }
        })
    }
    const setCheckbox = () => {
        setProduct(prev => {
            const {isPromotion, promotionAmount, basePrice} = prev.promotion;
            return {
                ...prev,
                promotion: {
                    isPromotion: !isPromotion,
                    promotionAmount: isPromotion ? 0 : promotionAmount,
                    basePrice: isPromotion ? 0 : prev.price
                },
                price: isPromotion ? basePrice : promotionAmount,
                attribute: !isPromotion ? {id: 3, name: 'PROMOCJA'} : {id: 0, name: ""}
            };
        });
    };


    return (
        <div className="admin__panel__section__from__section promotion">
            <span
                className="admin__panel__section__from__section-title col-18 col-md-4 col-lg-3 col-hd-2">PROMOCJA</span>
            <div className="admin__panel__section__from__section-inputs col-18 col-md-14 col-lg-15 col-hd-16">
                <div className="col-18 col-md-12 promotion__info">
                    <p className="promotion__info-text">
                        Cena podstawowa:
                    </p>
                    <span
                        className="promotion__info-amount">{product.promotion.basePrice} PLN.</span>
                </div>
                <div className="col-18 col-md-12 promotion__info">
                    <p className="promotion__info-text">
                        Najniższa cena w ciągu ostatnich 30 dni:
                    </p>
                    <span
                        className="promotion__info-amount">{product.lowestPriceInThirtyDays.lowestPrice} PLN.</span>
                </div>
                <CheckBox
                    name="isPromotion"
                    setChecked={setCheckbox}
                    checked={product.promotion.isPromotion}
                    label="Włącz promocję"
                    className=""
                />
                <input
                    className={!check ? 'section-input col-18 col-md-12' : isValid ? 'section-input col-18 col-md-12' : 'section-input col-18 col-md-12 not-valid'}
                    type="number"
                    min={0}
                    name="promotionAmount"
                    data-id="promotionAmount"
                    placeholder="Wartość promocji w procentach (1-100)"
                    onChange={setInput}
                    disabled={!product.promotion.isPromotion}
                    value={product.promotion.promotionAmount}
                />
            </div>
        </div>
    );
};

export default AdminEditPromotion;