import React, {useEffect} from 'react';
import {useGetProductsQuery} from "../../Products/producsApiSlice";
import {Link} from "react-router-dom";
import ChangeProductQuantities from "../../../components/Reusable/ChangeProductQuantities";
import useConfig from "../../../hooks/useConfig";

const AdminOrderProductItem = ({orderProduct, setOrder}) => {
    const {_id, id, quantity, availableQuantity, price} = orderProduct
    const isGiftCard = orderProduct.name === "Karta podarunkowa"
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()


    const {product} = useGetProductsQuery("productList", {
        selectFromResult: ({data}) => ({
            product: data?.entities[id]
        })
    });

    useEffect(() => {
        if (!product) return
        let availableQuantity = isGiftCard
            ? 1
            : product.quantities.colors.find(el => el.color === orderProduct.color && el.size === orderProduct.size).quantity

        setOrder(prev => {
            const products = prev.products.map(el => el._id === _id ? {...el, availableQuantity} : el)
            return {
                ...prev,
                products
            }
        })
    }, [product]);


    const increase = () => {
        if (!availableQuantity) return
        setOrder(prev => {
            const products = prev.products.map(el => el._id === _id
                ? {
                    ...el,
                    availableQuantity: el.name === "Karta podarunkowa" ? el.availableQuantity : el.availableQuantity--,
                    quantity: el.quantity++,
                    change: el.change++
                }
                : el)

            const giftCards = isGiftCard
                ? prev.payment.giftCards + price
                : prev.payment.giftCards

            const updatedProducts = isGiftCard
                ? prev.payment.products
                : prev.payment.products + price

            const discountAmount = prev.payment.discount.type === "procent"
                ? updatedProducts * prev.payment.discount.amount
                : prev.payment.discountAmount

            const total = updatedProducts - discountAmount + giftCards + prev.payment.shipment


            return {
                ...prev,
                payment: {
                    ...prev.payment,
                    giftCards,
                    products: updatedProducts,
                    total,
                    discountAmount
                },
                products,
            }
        })
    }

    const decrease = () => {
        if (!quantity) return
        setOrder(prev => {
            const products = prev.products.map(el => el._id === _id
                ? {
                    ...el,
                    availableQuantity: el.name === "Karta podarunkowa" ? el.availableQuantity : el.availableQuantity--,
                    quantity: el.quantity--,
                    change: el.change--
                }
                : el)
            const giftCards = isGiftCard
                ? prev.payment.giftCards - price
                : prev.payment.giftCards

            const updatedProducts = isGiftCard
                ? prev.payment.products
                : prev.payment.products - price

            const discountAmount = prev.payment.discount.type === "procent"
                ? updatedProducts * prev.payment.discount.amount
                : prev.payment.discountAmount

            const total = updatedProducts - discountAmount + giftCards + prev.payment.shipment
            return {
                ...prev,
                payment: {
                    ...prev.payment,
                    giftCards,
                    products: updatedProducts,
                    total,
                    discountAmount
                },
                products,
            }
        })
    }

    const handleRemoveProduct = () => {
        setOrder(prev => {
            const products = prev.products.map(el => el._id === _id
                ? {
                    ...el,
                    availableQuantity: el.availableQuantity + quantity,
                    quantity: 0,
                    change: el.change - quantity
                }
                : el)
            return {
                ...prev,
                payment: {
                    ...prev.payment,
                    products: prev.payment.products - (price * quantity),
                    total: prev.payment.products - (price * quantity) + prev.payment.shipment
                },
                products
            }
        })
    }

    if (!product) return null;
    const path = `${SERVER_ADDRESS}/img/${product?.id}/${product?.pictures[0]}`;
    return (
        <div className='row order__product'>
            <div className="col-8">
                <Link className={'order__product-link'}
                      to={product.kind === 'Karta podarunkowa' ? '/shop/gift_card' : `/shop/${product?.name}`}>
                    <img className='order__product-image'
                         src={path}
                         alt={product?.name}/>
                </Link>
            </div>
            <div className={`order__product-info-content col-10`}>
                <p className='order__product-kind'> {product?.kind} | {orderProduct.color}</p>
                <h2 className='order__product-name'>{product?.name}</h2>
                <p className='order__product-size'>{orderProduct.size}</p>
                <p className='order__product-price'>cena</p>
                <p className='order__price-tag'>{orderProduct.price} PLN</p>
                <ChangeProductQuantities
                    quantity={quantity}
                    increase={increase}
                    decrease={decrease}
                    handleRemoveProduct={handleRemoveProduct}
                    availableQuantity={availableQuantity}
                />
            </div>
        </div>
    );
};

export default AdminOrderProductItem;