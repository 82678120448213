import React, {useEffect, useState} from 'react';
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link} from "react-router-dom";
import AdminDiscountRow from "./AdminDiscountRow";
import {useGetDiscountsQuery} from "../../Discounts/discountApiSlice";

const AdminDiscountsList = () => {
    const [err, setErr] = useState()
    const {
        data: discounts,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDiscountsQuery('discountsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
            setErr(errorMessage);
        }

    }, [error, isError]);

    const clearError = () => setErr(null);

    let result
    if (isSuccess) {
        const {ids} = discounts
        const rows = ids.map((id, i) => {
            return <AdminDiscountRow
                key={id}
                id={id}
                setError={setErr}
                number={i + 1}
            />
        })

        if (isSuccess) result = (
            <section className="admin__panel__section admin__clients col-18 col-lg-16">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <h1 className="admin__panel__section-title">KODY RABATOWE</h1>
                <div className="admin__panel__section__buttons">
                    <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                    <Link className="admin__panel__section__buttons-button  yellow-button"
                          to="add_discount">Dodaj</Link>
                </div>
                <div className="admin__panel__section__list admin__clients__list ">
                    <div className="admin__panel__section__list-item col-3 list-header">Aktywuj</div>
                    <div className="admin__panel__section__list-item col-4 list-header">Nazwa</div>
                    <div className="admin__panel__section__list-item col-2 list-header">Kwota</div>
                    <div className="admin__panel__section__list-item col-4 list-header">Typ</div>
                    <div className="admin__panel__section__list-item col-3 list-header">Wielokrotny</div>
                    <div className="admin__panel__section__list-item col-2 list-header">Edycja</div>
                </div>
                {rows}
            </section>
        );
        return result
    }
};

export default AdminDiscountsList;