import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";
import {logOut, logoutAndReset} from "../Auth/authSlice";

const subscribersAdapter = createEntityAdapter({});

const initialState = subscribersAdapter.getInitialState();

export const subscriberApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubscribers: builder.query({
            query: () => ({
                url: '/api/subscribe',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            transformResponse: responseData => {
                const loadedSubscribers = responseData.map(subscriber => {
                    subscriber.id = subscriber._id;
                    return subscriber;
                });
                return subscribersAdapter.setAll(initialState, loadedSubscribers);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Subscriber', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Subscriber', id}))
                    ];
                } else return [{type: 'Subscriber', id: 'LIST'}];
            }
        }),

        addNewSubscriber: builder.mutation({
            query: (initialSubscriberData) => ({
                url: '/api/subscribe',
                method: 'POST',
                body: {
                    ...initialSubscriberData
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Client', id: arg.client.clientId}
            ]
        }),

        updateSubscriber: builder.mutation({
            query: (initialSubscriberData) => ({
                url: '/api/subscribe',
                method: 'PATCH',
                body: {
                    ...initialSubscriberData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Client', id: arg.client},
            ],
        }),

        deleteSubscriber: builder.mutation({
            query: (id) => ({
                url: '/api/subscribe',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Subscriber', id: arg.id}
            ],
            // Additional logic to handle logout
            async onQueryStarted(arg, {dispatch, getState, queryFulfilled}) {
                try {
                    // Check if admin is deleting subscriber account
                    const loggedInID = getState().auth.id
                    if (loggedInID !== arg.id) return

                    // If subscriber is deleting his account
                    await queryFulfilled;
                    dispatch(logoutAndReset());

                    // Reset the API state after a short delay
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                        dispatch(subscriberApiSlice.util.resetApiState())
                    }, 1000);
                } catch (err) {
                    console.log(err.error?.data?.message);
                }
            }
        })
    })
});

export const {
    useGetSubscribersQuery,
    useAddNewSubscriberMutation,
    useUpdateSubscriberMutation,
    useDeleteSubscriberMutation
} = subscriberApiSlice;

// returns the query object
export const selectSubscribersResult = subscriberApiSlice.endpoints.getSubscribers.select();

// creates memoized selector
const selectSubscribersData = createSelector(
    selectSubscribersResult,
    subscribersResult => subscribersResult.data // normalized state object with ids & entities
);

export const {
    selectAll: selectAllSubscribers,
    selectById: selectSubscriberById,
    selectIds: selectSubscriberIds
    // pass in a selector that returns the subscribers slice of state
} = subscribersAdapter.getSelectors(state => selectSubscribersData(state) ?? initialState);
