import AdminOrderProductItem from "./AdminOrderProductItem";

const AdminOrderProductList = ({orderProducts, payment, setOrder}) => {
    const productList = orderProducts.map(orderProduct => <AdminOrderProductItem
        key={orderProduct._id}
        orderProduct={orderProduct}
        setOrder={setOrder}
    />)
    const giftCardCounter = orderProducts.reduce((acc, product) => {
        if (product.name === "Karta podarunkowa") {
            return acc + product.quantity
        }
        return acc
    }, 0)
    return (
        <div className="col-18 col-lg-8 order__products">
            <h2 className="order__products-title">Koszyk</h2>
            {productList}
            <div className="order__products-subtotal">
                <div className="subtotal__item">
                    <span className="subtotal__item-title">Koszt produktów</span>
                    <span className="subtotal__item-products">{payment.products} PLN</span>
                </div>
                {!!payment.discountAmount && <div className="subtotal__item">
                    <span className="subtotal__item-title">{payment.discount.type === "procent"
                        ? `${payment.discount.name} ${payment.discount.amount * 100}%`
                        : payment.discount.name}</span>
                    <span className="subtotal__item-shipment">-{payment.discountAmount} PLN</span>
                </div>}
                {!!payment.giftCards && <div className="subtotal__item">
                    <span
                        className="subtotal__item-title">{giftCardCounter > 1 ? "Karty podarunkowe" : "Karta podarunkowa"}</span>
                    <span className="subtotal__item-shipment">{payment.giftCards} PLN</span>
                </div>}
                <div className="subtotal__item">
                    <span className="subtotal__item-title">Koszt wysyłki</span>
                    <span className="subtotal__item-shipment">{payment.shipment} PLN</span>
                </div>
            </div>
            <div className="order__products-total">
                <div className="total__item">
                    <span className="total__item-title">Suma</span>
                    <span className="total__item-payment">{payment.total} PLN</span>
                </div>
                <p className="total-tax">W tym podatek: <span>0</span></p>
            </div>
        </div>
    );
};

export default AdminOrderProductList;