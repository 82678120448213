export const fetchFile = async (path, fileName) => {
    try {
        const response = await fetch(path);
        if (!response.ok) {
            throw new Error(`Failed to fetch image: ${fileName}`);
        }
        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(url);
    } catch (err) {
        console.error(`Failed to load ${fileName})`)
        throw new Error(`Failed to load ${fileName} ${err.message}`)
        return [];
    }
}