export const validateProductData = (product, CONFIG) => {
    const {CONSTANTS: {PRODUCT_ATTRIBUTES, PRODUCT_KIND}} = CONFIG

    const {
        attribute,
        pictures,
        description,
        name,
        kind,
        quantities,
        price,
        sizeChartInfo,
        material,
        amounts,
        selectedFiles
    } = product

    const validateKind = (option, list) => list.some(el => el.name === option);
    const validateAttribute = (option, list) => option?.id ? list.some(el => el.name === option) : true;

    const isValid = {
        name: name !== "",
        attribute: validateAttribute(attribute.name, PRODUCT_ATTRIBUTES),
        kind: validateKind(kind.name, PRODUCT_KIND),
        quantities: !!quantities.colors.length,
        price: typeof price === "number" && price > 0,
        sizeChartInfo: sizeChartInfo !== "",
        material: material !== "",
        description: description !== "",
        amounts: amounts.length ? amounts.every(el => typeof el === "number") : true,
        selectedFiles: selectedFiles.length !== 0
    }
    const all = Object.values(isValid).every(el => el === true)

    return {...isValid, all}
}