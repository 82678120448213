export const validateClient = (client, REG_EX) => {
    const {
        name,
        surname,
        email,
        password,
        password2,
        accept,
    } = client
    const isValid = {
        name: REG_EX.NAME.test(name),
        surname: REG_EX.NAME.test(surname),
        email: REG_EX.EMAIL.test(email),
        password: REG_EX.PASSWORD.test(password),
        password2: REG_EX.PASSWORD.test(password2),
        accept: accept === true
    }
    const all = Object.values(isValid).every(el => el === true)

    return {...isValid, all}
}