import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetProductsQuery} from "../Products/producsApiSlice";
import {
    addItem,
    deleteItem,
    removeItem,
    selectCartError,
    updateCartAfterAction,
    setIsAllInStock
} from "./cartSlice";
import {Link, useNavigate} from "react-router-dom";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import ChangeProductQuantities from "../../components/Reusable/ChangeProductQuantities";
import {getErrorMessage} from "../../utils/getErrorMessage";
import useConfig from "../../hooks/useConfig";

const CartProduct = ({cartItem, styling}) => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const dispatch = useDispatch();
    const {id, color, size, quantity} = cartItem;
    const navigate = useNavigate();
    const {totalCartItems} = useSelector((state) => state.cart.cart);
    const [err, setErr] = useState(null)
    const [inStock, setInStock] = useState({
        inStock: true,
        availableQuantity: null
    })
    const cartError = useSelector(selectCartError);

    // Local state for managing available quantity and UI state for increase/decrease buttons
    const [state, setState] = useState({
        availableQuantity: null,
    });

    // Get the product data from Redux store using RTK Query
    const {product} = useGetProductsQuery("productList", {
        selectFromResult: ({data}) => ({
            product: data?.entities[id]
        }),
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    // Effect to update available quantity and total price when product data changes
    useEffect(() => {
        if (product) {
            const {quantity: productQuantity} = Object.values(product?.quantities.colors).find(el => {
                return el.color === cartItem.color && el.size === cartItem.size
            })
            const checkInStock = (productQuantity >= cartItem.quantity)

            setState(prevState => ({
                ...prevState,
                availableQuantity: productQuantity - quantity
            }))

            setInStock({
                inStock: checkInStock,
                availableQuantity: productQuantity
            })
            dispatch(setIsAllInStock(checkInStock))
        }
    }, [product]);

    useEffect(() => {
        if (product) {
            const {quantity: productQuantity} = Object.values(product?.quantities.colors).find(el => {
                return el.color === cartItem.color && el.size === cartItem.size
            })

            const checkInStock = productQuantity >= cartItem.quantity
            setInStock({
                inStock: checkInStock,
                availableQuantity: productQuantity
            })
            dispatch(setIsAllInStock(checkInStock))
        }
    }, [totalCartItems]);

    useEffect(() => {
        if (cartError) setErr(getErrorMessage(cartError));
    }, [cartError]);

    const clearError = () => {
        setErr(null);
    };

    // Increase the quantity of the item in the cart
    const increase = () => {
        if (!state.availableQuantity) {
            console.log("Product is not available in this quantity");
            return;
        }

        dispatch(updateCartAfterAction(addItem({id, color, size})));

        if (!cartError) setState(prevState => ({
            ...prevState,
            availableQuantity: state.availableQuantity - 1
        }));
    };

    // Decrease the quantity of the item in the cart
    const decrease = () => {
        if (quantity > 1) {
            dispatch(updateCartAfterAction(removeItem({id, color, size})));

            if (!cartError) setState(prevState => ({
                ...prevState,
                availableQuantity: prevState.availableQuantity + 1
            }));

        } else {
            dispatch(updateCartAfterAction(deleteItem({id, color, size})));
        }
    };

    // Handle removing the product from the cart
    const handleRemoveProduct = () => {
        dispatch(updateCartAfterAction(deleteItem({id, color, size})));
        if (!cartError && !totalCartItems) navigate("/");
    };

    if (!product) return null;
    const path = `${SERVER_ADDRESS}/img/${product.id}/${product.pictures[0]}`;
    const {kind, name, price, promotion: {isPromotion, basePrice}} = product

    return (
        <div className='row cart__product'>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <div className={styling[0]}>
                <Link className={'cart__product-link'}
                      to={`/shop/${product.name}`}>
                    <img className='cart__product-image'
                         src={path}
                         alt={product.name}/>
                </Link>
            </div>
            <div className={`${styling[1]} cart__product-info-content`}>
                <p className='cart__product-kind'> {kind} | {color}</p>
                <h2 className='cart__product-name'>{name}</h2>
                <p className='cart__product-price'>cena</p>
                <p className='cart__product__price-tag'>{price}PLN
                    {isPromotion
                        ? <span className="base-price">{basePrice} PLN</span>
                        : null
                    }
                </p>
                <p className='cart__product-size'>rozmiar: {size}</p>
                <p className='cart__product-quantity'>ilość: {quantity}</p>
                <ChangeProductQuantities
                    availableQuantity={state.availableQuantity}
                    quantity={cartItem.quantity}
                    increase={increase}
                    decrease={decrease}
                    handleRemoveProduct={handleRemoveProduct}/>
                {!inStock.inStock &&
                    <p className="error-message">{`Dostępnych sztuk: ${inStock.availableQuantity}`}</p>}
            </div>
        </div>
    );
};

export default React.memo(CartProduct);
