import React, {useEffect, useState} from 'react';
import CountrySelect from "../../../Orders/OrderForm/CountrySelect";
import CheckBox from "../../../../components/Reusable/CheckBox";
import SaveButton from "../../../../components/Reusable/SaveButton";
import {useGetClientQuery, useUpdateClientMutation} from "../../clientApiSlice";
import {Link, useNavigate} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import ClientPanelHeader from "../ClientPanelHeader";
import ErrorPopup from "../../../../components/Reusable/ErrorPopup";
import {ReactComponent as Close} from '../../../../images/close.svg';
import {validateAddressData} from "../../../../utils/validateAddressData";
import useConfig from "../../../../hooks/useConfig";
import {getErrorMessage} from "../../../../utils/getErrorMessage";


const BillingData = () => {
    const navigate = useNavigate()
    const {email} = useAuth()
    const [err, setErr] = useState(null);
    const [formError, setFormError] = useState("")
    const [check, setCheck] = useState(false)
    const CONFIG = useConfig()
    const blancData = {
        theSame: true,
        name: "",
        surname: "",
        email: "",
        phone: "",
        postCode: "",
        street: "",
        city: "",
        country: {id: 21, name: "Poland", code: "PL", language: "pl"},

    }
    // Initial data for form fields' validity
    const [isValid, setIsValid] = useState({
        name: false,
        surname: false,
        email: false,
        phone: false,
        postCode: false,
        street: false,
        city: false,
        country: false,
        all: false,
    });

    const [data, setData] = useState(blancData)

    const {
        data: client,
        isLoading: isClientLoading,
        isSuccess: isClientSuccess,
        isError: isClientError,
        error: clientError
    } = useGetClientQuery(email, {
        skip: !email,
    });

    // RTK Query for adding a new order
    const [updateClient, {
        data: updatedClient,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateClientMutation();


    useEffect(() => {
        if (client) {
            const billingData = {
                ...client.addresses.billingAddress
            }
            setData(billingData)
        }
    }, [client, isClientSuccess]);

    useEffect(() => {
        if (isSuccess) navigate('/account/edit/')
    }, [updatedClient, isSuccess]);

    useEffect(() => {
        if (client) {
            const {addresses: {billingAddress}} = client
            setData(billingAddress)
        }
    }, [client, isClientSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    // Validate form data whenever the data state changes
    useEffect(() => {
        setFormError(null);
        if (!data.theSame) setIsValid(validateAddressData(data, CONFIG))
    }, [data]);

    const clearError = () => {
        setErr(null);
    };

    const setCheckBox = (value) => {
        setData(prev => {
            const {addresses: {billingAddress}} = client
            if (!prev.theSame) {
                setCheck(false)
                return blancData
            } else {
                return {...billingAddress, theSame: false}
            }
        })
    }

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        setData(prev => ({...prev, [id]: value, theSame: false}))
    }
    const setCountry = (country) => {
        setData(prev => ({...prev, country}))
    }

    const onSaveDataClicked = async (e) => {
        e.preventDefault()

        if (!data.theSame) setCheck(true)

        if (!isValid.all && !data.theSame) {
            setFormError("Wypełnij wszystkie pola.")
            return
        }

        const dataToSave = {
            id: client.id,
            addresses: {
                ...client.addresses,
                billingAddress: data
            }
        }
        try {
            await updateClient(dataToSave).unwrap()
        } catch (err) {
            console.log(err?.data?.message || err?.message || err?.error || 'An error occurred')

        }
    }

    return (
        <div className="client__edit">
            <ClientPanelHeader client={client}/>
            <Link
                className="close-button"
                to={'/account/edit/'}
            ><Close/></Link>

            <div className="col-18 client__panel__content">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <form
                    className="client__edit__form col-18 col-lg-9"
                    onSubmit={onSaveDataClicked}>
                    <h2 className="client__edit__form-title">DANE ROZLICZENIOWE</h2>
                    <CheckBox
                        name="theSame"
                        label="Takie same jak moje dane."
                        checked={data.theSame}
                        setChecked={setCheckBox}
                    />
                    <div className='row'>
                        <input
                            className={!check ? 'custom-input col-18' : isValid.name ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Imię'
                            type='text'
                            name='name'
                            data-id="name"
                            value={data.name}
                            onChange={setInput}

                        />
                        <input
                            className={!check ? 'custom-input col-18' : isValid.surname ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Nazwisko'
                            type='text'
                            name='surname'
                            data-id="surname"
                            value={data.surname}
                            onChange={setInput}
                        />
                    </div>
                    <CountrySelect
                        selected={data.country}
                        setSelected={setCountry}
                    />
                    <input
                        className={!check ? 'custom-input col-18' : isValid.street ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                        type="text"
                        name="street"
                        data-id="street"
                        placeholder="Ulica i nr domu"
                        value={data.street}
                        onChange={setInput}

                    />
                    <div className='row'>
                        <input
                            className={!check ? 'custom-input col-18' : isValid.postCode ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Kod pocztowy'
                            type='text'
                            name='postCode'
                            data-id="postCode"
                            value={data.postCode}
                            onChange={setInput}
                        />
                        <input
                            className={!check ? 'custom-input col-18' : isValid.city ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                            placeholder='Miasto'
                            type='text'
                            name='city'
                            data-id="city"
                            value={data.city}
                            onChange={setInput}
                        />
                    </div>
                    <input
                        className={!check ? 'custom-input col-18' : isValid.phone ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                        placeholder='Telefon'
                        type='text'
                        name='phone'
                        data-id="phone"
                        value={data.phone}
                        onChange={setInput}
                    />
                    <input
                        className={!check ? 'custom-input col-18' : isValid.email ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                        placeholder='e-mail'
                        type='text'
                        name='email'
                        data-id="email"
                        value={data.email}
                        onChange={setInput}
                    />
                    <SaveButton
                        className="link-button"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                        errorMessage={formError}
                        text="ZAPISZ"
                        isLoadingText="ZAPISUJĘ..."
                    />
                </form>
            </div>
        </div>
    );
};

export default BillingData;