import {useEffect, useRef, useState} from "react";
import {useLoginMutation} from "./authApiSlice";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux'
import {setCredentials} from "./authSlice";
import usePersist from "../../hooks/usePersist";
import InnerSection from "../../components/Reusable/InnerSection";
import CheckBox from "../../components/Reusable/CheckBox";
import {ReactComponent as Close} from "../../images/close.svg";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import useAuth from "../../hooks/useAuth";
import {jwtDecode} from "jwt-decode";
import {ReactComponent as NotVisibleEye} from '../../images/Icon feather-eye-off.svg';
import PasswordInput from "../../components/Reusable/PasswordInput";
import {getErrorMessage} from "../../utils/getErrorMessage";


const Login = () => {
    const emailRef = useRef()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [err, setErr] = useState(null);
    const [formError, setFormError] = useState(null)
    const [persist, setPersist] = usePersist()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [login, {isLoading, isError, error}] = useLoginMutation()
    const {isLoggedIn} = useAuth()

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/")
        } else {
            emailRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setFormError('')
    }, [email, password]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const handleUserInput = (e) => setEmail(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const onCreateAccountClicked = () => {
        navigate('/create_account')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!email.length) {
                setFormError("Podaj e-mail.")
                return
            }
            if (!password.length) {
                setFormError("Podaj hasło.")
                return
            }
            const {accessToken} = await login({email, password, persist}).unwrap()
            const decoded = jwtDecode(accessToken)
            const {isAdmin} = decoded.UserInfo
            dispatch(setCredentials({accessToken}))
            setEmail("")
            setPassword("")

            localStorage.removeItem("orderFormData")
            localStorage.removeItem("shoppingCart")

            if (isAdmin) {
                navigate("/admin")
                return
            }
            navigate("/account")
        } catch (err) {
            console.error(getErrorMessage(err))
        }
    }


    const content = (
        <>
            <InnerSection>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <div className='login__content'>
                    <Link className="close-button" to="/"><Close/></Link>
                    <form
                        onSubmit={handleSubmit}
                        className='login__content__column login__content__column-left login__left'>
                        <h2 className="login__content__column-title"> Logowanie</h2>
                        <input
                            className='custom-input'
                            type='email'
                            name='email'
                            placeholder='E-mail'
                            onChange={handleUserInput}
                            ref={emailRef}
                            autoComplete="on"
                        />

                        <PasswordInput handlePwdInput={handlePwdInput}/>

                        <CheckBox
                            name={"persist"}
                            label={"zapamiętaj mnie"}
                            checked={persist}
                            setChecked={handleToggle}
                        />
                        <div className="button-group">
                            <span className='error-message'>{formError}</span>
                            <button
                                className='link-button'
                                disabled={isLoading}>
                                {isLoading ? "Loading..." : "ZALOGUJ SIĘ"}
                            </button>
                            <Link className='reset-password'
                                  to="/reset_password">Nie pamiętam hasła</Link>
                        </div>

                    </form>
                    <div className='login__content__column login__content__column-right'>
                        <h2 className="login__content__column-title"> Rejestracja</h2>
                        <p className='login__content__column-text'> NIE MASZ KONTA?</p>
                        <p className='login__content__column-text'> ZAREJESTRUJ SIĘ TERAZ:</p>
                        <ul className='login__content__column-list'>
                            <li className='login__content__column__list-item'>Bądź na bieżąco z nowościami</li>
                            <li className='login__content__column__list-item'>Pełen dostęp do historii zamówień</li>
                            <li className='login__content__column__list-item'>Śledzenie statusu zamówienia</li>
                            <button onClick={onCreateAccountClicked} className='link-button'>STWÓRZ KONTO</button>
                        </ul>

                    </div>
                </div>
            </InnerSection>
        </>
    );


    return isLoggedIn ? null : content
}
export default Login
