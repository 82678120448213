import React, {useState} from 'react';

const AdminEditGiftCardAddAmount = ({state, check, isValid, setState}) => {
    const [data, setData] = useState("")
    const [formError, setFormError] = useState(null)

    const setInput = (e) => {
        setData(Number(e.target.value))
    }

    const setAmounts = () => {
        setFormError(null)
        if (state.amounts.some(el => el === data)) {
            setFormError("Podana kwota już istnieje")
            setData("")
            return
        }

        setState(prev => {
            const amounts = [...prev.amounts]
            amounts.push(data)
            amounts.sort((a, b) => a - b)
            return {...prev, amounts}
        })
        setData("")
    }

    const onRemoveAmountClicked = () => {
        const index = state.amounts.findIndex(el => el === data)
        if (index === -1) {
            setFormError("Nie odnaleziono")
            return
        }
        setData("")
        setFormError(null)

        setState(prev => {
            const index = state.amounts.findIndex(el => el === data)
            const amounts = [...prev.amounts]
            amounts.splice(index, 1)
            return {
                ...prev,
                amounts
            }
        })
    }

    const onClearListClicked = (e) => {
        setFormError(null)
        setState(prev => ({
            ...prev,
            amounts: []
        }))
    }

    const onSelectAmountClicked = (e) => {
        const amount = e.target.dataset.id
        setData(Number(amount))
    }

    const list = state.amounts.map((el, i) =>
        <span
            key={i}
            data-id={el}
            className="add__amount__list__items-item"
            onClick={onSelectAmountClicked}
        >{el}</span>
    )
    return (
        <div className="admin__panel__section__from__section add__amount">
            <span
                className="admin__panel__section__from__section-title col-18 col-md-4 col-lg-3 col-hd-2">KWOTY</span>
            <div className="admin__panel__section__from__section-inputs col-18 col-md-14 col-lg-15 col-hd-16">
                <input
                    className={!check ? 'section-input col-18 col-md-12' : isValid ? 'section-input col-18 col-md-12' : 'section-input col-18 col-md-12 not-valid'}
                    type="number"
                    min={0}
                    placeholder="Kwota"
                    value={data}
                    onChange={setInput}
                />
                <div className="add__amount__buttons">
                    <button
                        className="admin-button"
                        type="button"
                        onClick={setAmounts}
                        disabled={!data}
                    >Dodaj
                    </button>
                    <button
                        className="admin-button"
                        type="button"
                        onClick={onRemoveAmountClicked}
                        disabled={!data}
                    >Usuń
                    </button>
                </div>
                <span className='error-message'>{formError}</span>
                <div className="add__amount__list">
                    <div className="add__amount__list__items">{list}</div>
                    <button
                        className="admin-button add__amount__list-remove"
                        onClick={onClearListClicked}
                        type="button"
                        disabled={!list.length}
                    >Usuń
                    </button>
                </div>
            </div>

        </div>
    );
};

export default AdminEditGiftCardAddAmount;