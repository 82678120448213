export const validateGiftCardData = (giftCard) => {
    const {attribute, description, name, kind, selectedFiles, price, sizeChartInfo, material, amounts} = giftCard

    const isValid = {
        name: name === "Karta podarunkowa",
        attribute: attribute === "",
        kind: kind === "Karta podarunkowa",
        price: price === 0,
        sizeChartInfo: sizeChartInfo !== "",
        material: material === "Brak",
        description: description !== "",
        amounts: amounts.length !== 0 && amounts.every(el => typeof el === "number"),
        selectedFiles: selectedFiles.length !== 0
    }
    const all = Object.values(isValid).every(el => el === true)

    return {...isValid, all}
}