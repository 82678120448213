import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";
import {useGetMarkdownQuery} from "../../app/configApiSlice";
import React, {useEffect, useState} from "react";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import DOMPurify from "dompurify";
import {marked} from "marked";
import {fetchFile} from "../../utils/fetchFile";
import useConfig from "../../hooks/useConfig";

const Returns = () => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMarkdownQuery('returns.md');

    const [err, setErr] = useState()

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';

            setErr(errorMessage);
        }
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const onGetFormClicked = async (e) => {
        const fileName = e.target.dataset.id
        const path = `${SERVER_ADDRESS}/pdf/${fileName}`
        try {
            await fetchFile(path, fileName)
        } catch (err) {
            setErr(err.message)
        }
    }

    let content
    if (isLoading) content = <h1>Loading</h1>
    if (isSuccess && data) content =
        <div className="md-body"
             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(data.content))}}/>

    return (
        <>
            <InnerSection>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <div className='returns__content'>
                    <div className='returns__content__column returns__content__column-left'>
                        <h2 className="returns__content__column-title">Zwroty i wymiana</h2>
                        <div className="md-body">
                            <p>Jeżeli zakupione produkty nie spełniają Twoich oczekiwań i chciałbyś odstąpić od umowy
                                lub
                                dokonać wymiany, masz na to czas 14 dni od daty otrzymania przesyłki. Aby zwrócić
                                produkty,
                                prosimy o ich bezpieczne zapakowanie, łącznie z formularzem i odesłanie na adres:
                            </p>
                            <h3><strong>BeBrave ul.Obozowa 16/105, 01-161 Warszawa</strong></h3>
                        </div>
                        <div className="forms">
                            <button
                                className="link-button"
                                onClick={onGetFormClicked}
                                data-id="FORMULARZ_REKLEMACYJNY.pdf"
                            >FORMULARZ REKLEMACYJNY
                            </button>
                            <button
                                className="link-button"
                                onClick={onGetFormClicked}
                                data-id="FORMULARZ_WYMIANY_TOWARU.pdf"
                            >FORMULARZ WYMIANY TOWARU
                            </button>
                            <button
                                className="link-button"
                                onClick={onGetFormClicked}
                                data-id="FORMULARZ_ZWROTU_TOWARU.pdf"
                            >FORMULARZ ZWROTU TOWARU
                            </button>
                        </div>
                        <div className="md-body">
                            <p> Wydrukowany i wypełniony formularz umieść w paczce do zwrotu. Dane te są
                                niezbędne do dokonania zwrotu, wymiany bądź reklamacji. Koszt zwrotu pokrywa nadawca.
                            </p>
                        </div>

                    </div>
                    <div className='returns__content__column returns__content__column-right'>
                        {content}
                    </div>
                </div>

            </InnerSection>
            <NewsLetter/>
        </>
    );
};

export default Returns;