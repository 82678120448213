import {useEffect, useState} from "react";


const usePersist = () => {
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || false)

    useEffect(() => {
        localStorage.setItem("persist", JSON.stringify(persist))
    }, [persist, setPersist]);
    
    return [persist, setPersist]
}

export default usePersist