import {useState} from "react";
import useConfig from "../../../hooks/useConfig";

const CountrySelect = ({selected, setSelected}) => {
    const [isVisible, setIsVisible] = useState(false)
    const {CONSTANTS: {COUNTRIES}} = useConfig()
    const handleClick = (e) => {

        setSelected({
            ...e.target.dataset,
            id: Number(e.target.dataset.id),
        })
        setIsVisible(!isVisible)
    }

    const optionsList = COUNTRIES.map(opt => <li
        key={opt.id}
        className={"option"}
        data-id={opt.id}
        data-name={`${opt.name}`}
        onClick={handleClick}>{opt.name}
    </li>)

    return (
        <div className={"custom__select"}>
            <div
                className={isVisible ? "selected_option selected_option-visible" : "selected_option"}
                tabIndex={0}
                onClick={() => setIsVisible(!isVisible)}
            >
                        <span
                            className={selected?.id !== 0 ? "select__content" : "select__content select__content-hidden"}>
                            {selected?.id === 0 ? `Kraj/region` : selected?.name}</span>
                <span className={"select_decor"}></span>
            </div>
            {isVisible && <ul className={"options"}>{optionsList}</ul>}
        </div>
    )
}

export default CountrySelect