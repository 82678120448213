import React, {useEffect} from 'react';
import InnerSection from "../../../components/Reusable/InnerSection";
import {useLocation, useNavigate} from "react-router-dom";
import {useConfirmClientQuery} from "../clientApiSlice";
import useQuery from "../../../hooks/useQuery";

const ConfirmAccount = () => {
    const navigate = useNavigate()
    const token = useQuery('token');

    useEffect(() => {
        if (!token) {
            navigate("/")
        }
    }, [token]);

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useConfirmClientQuery(token, {
        skip: !token // Skip the query if token is empty
    });

    let result

    if (isError) {
        console.log(error?.data?.message);
        if (error.status === 400) result = (
            <h1>Link aktywacyjny stracił ważność albo jest wadliwy.</h1>)
        else if (error.status === 409) result = (<h1>Konto aktywne. Udanych zakupów!</h1>)
        else (<h1>Coś poszło nie tak.</h1>)
    }

    if (isSuccess) {
        console.log(data?.message);
        result = (<h1>Konto klienta aktywowane</h1>)
    }

    if (!token) return null
    return (
        <InnerSection>
            {result}
        </InnerSection>
    );
}

export default ConfirmAccount;