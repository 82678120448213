import {ReactComponent as Ig} from "../../images/ig.svg";
import {ReactComponent as Fb} from "../../images/fb.svg";
import {ReactComponent as Mail} from "../../images/mail.svg";
import {Link} from "react-router-dom";

const Footer = () => {
    const footer = (
        <section className='footer'>
            <div className='row'>
                <div className='col-12 col-md-5 footer__card'>
                    <h1 className='footer__card-title'>INFORMACJE:</h1>
                    <Link to="rules" className="footer__card-content">Regulamin</Link>
                    <Link to="consts_and_shipping" className="footer__card-content">Koszty i metody dostawy</Link>
                    <Link to="gift_card_rules" className="footer__card-content">Regulamin Karty
                        prezentowej</Link>
                </div>
                <div className='col-12 col-md-5 footer__card'>
                    <h1 className='footer__card-title'>ZWROTY:</h1>
                    <Link to="returns" className="footer__card-content">Regulamin i formularze</Link>
                </div>
                <div className='col-12 col-md-5 footer__card'>
                    <h1 className='footer__card-title'>KONTAKT:</h1>
                    <p className="footer__card-content">kontakt@bebrawewewear.pl</p>
                    <p className="footer__card-content">tel. +48 510 669 711</p>
                    <p className="footer__card-content">pon-pt 9:00-17:00</p>
                    <div className='socials'>
                        <Link
                            className='social'
                            to="https://www.facebook.com/bebreve.wear"
                            target="_blank"
                            rel="noopener noreferrer">
                            <Fb/>
                        </Link>
                        <Link
                            className='social'
                            to="https://www.instagram.com/bebrave.wear/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Ig/>
                        </Link>
                        <Link
                            className='social'
                            to="mailto:test@test.pl">
                            <Mail/>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='copy'> &copy; BeBrave. All rights reserved.</div>
        </section>
    )
    return footer
};

export default Footer;