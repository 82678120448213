import React, {useEffect, useState} from 'react';
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link, useNavigate} from "react-router-dom";
import {useAddNewProductMutation} from "../../Products/producsApiSlice";
import {productObject} from "../Products/productObject";
import PanelSectionTextarea from "../AdminPanelSection/PanelSectionTextarea";
import AdminEditGiftCardAddAmount from "./AdminEditGiftCardAddAmount";
import PanelSectionCheckBox from "../AdminPanelSection/PanelSectionCheckBox";
import PanelSectionPhotoEdit from "../AdminPanelSection/PanelSectionPhotoEdit";
import {convertFilesToBase64} from "../../../utils/convertFilesToBase64";
import {validateGiftCardData} from "../../../utils/validateGiftCardData";

const AdminAddGiftCard = () => {
    const [err, setErr] = useState()
    const [formError, setFormError] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()
    const [check, setCheck] = useState(false)
    const [state, setState] = useState({
        ...productObject,
        name: "Karta podarunkowa",
        kind: "Karta podarunkowa",
        attribute: "",
        price: 0,
        material: "Brak",
        sizeChartInfo: "brak"
    })

    const [addGiftCard, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewProductMutation();


    useEffect(() => {
        setFormError(null);
        setIsValid(validateGiftCardData(state));
    }, [state]);

    useEffect(() => {
        if (isSuccess) navigate("/admin")
    }, [data, isSuccess]);

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
            setErr(errorMessage);
        }
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        if (e.target.type === "number") value = Number(value)
        setState(prev => ({...prev, [id]: value}))
    }

    const setCheckBox = (e) => {
        const {checked} = e.target;
        setState(prev => ({
            ...prev,
            display: checked
        }))
    }

    const onAddGiftCardClicked = async (e) => {
        e.preventDefault()
        // Check if form is valid
        setCheck(true)
        if (!isValid?.all) {
            console.log(isValid, state);
            setFormError('Dane produktu nie są kompletne.');
            console.log('Order data incomplete');
            return;
        }
        const files = state.selectedFiles.filter(el => el !== null)
        const base64Files = await convertFilesToBase64(files)
        const pictures = base64Files.map((file, i) => `${state.name}_${i + 1}.png`);

        const product = {
            ...state,
            pictures,
            attribute: state.attribute,
            selectedFiles: base64Files
        }

        try {
            await addGiftCard(product).unwrap()
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>Karta podarunkowa</h1>

            <div className="admin__panel__section__buttons">
                <Link className="admin__panel__section__buttons-button go-back blue-button" to="/admin">Wróć</Link>
                <span className='error-message'>{formError}</span>
                <div className="admin__panel__section__buttons-group">
                    <button
                        className=" admin__panel__section__buttons-button yellow-button"
                        onClick={onAddGiftCardClicked}
                    >{isLoading ? "Loading..." : "Zapisz"}
                    </button>
                </div>
            </div>

            <form className="admin__panel__section__from">

                <PanelSectionCheckBox
                    checked={state.display}
                    title="AKTYWNA"
                    name="active"
                    label="Karta aktywna"
                    setChecked={setCheckBox}
                />
                <AdminEditGiftCardAddAmount
                    isValid={isValid?.amounts}
                    check={check}
                    state={state}
                    setState={setState}

                />
                <PanelSectionPhotoEdit
                    state={state}
                    check={check}
                    isValid={isValid?.selectedFiles}
                    setFormError={setFormError}
                    setErr={setErr}
                    selectedFiles={state.selectedFiles}
                    setSelectedFiles={setState}
                />
                <PanelSectionTextarea
                    isValid={isValid?.description}
                    check={check}
                    value={state.description}
                    setInput={setInput}
                    title="OPIS"
                    name="description"
                    placeholder="Dodaj opis"
                />
            </form>
        </section>
    );
};

export default AdminAddGiftCard;