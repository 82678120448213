import {ReactComponent as Back} from '../../../images/back.svg';
import {ReactComponent as Exit} from '../../../images/exit.svg';
import {ReactComponent as Forward} from '../../../images/forward.svg';
import {useEffect, useCallback, useState, useRef} from 'react';
import {productPreviewHandleKeyDown} from "../../../utils/productPreviewHandleKeyDown";
import {setScrollBarInMiddle} from "../../../utils/setScrollBarInMiddle";
import {noBackgroundScroll} from "../../../utils/noBackgroundScroll";
import useConfig from "../../../hooks/useConfig";

const PicturePreview = ({product, selectedImage, setSelectedImage, closeModal}) => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const [zoomIn, setZoomIn] = useState(false);
    const containerRef = useRef(null)

    // Change photo forward function using useCallback for memoization
    const forward = useCallback(() => {
        const length = product.pictures.length;
        const index = product.pictures.findIndex(el => el === selectedImage.fileName);
        const fileName = (index + 1 === length) ? product.pictures[0] : product.pictures[index + 1];

        setSelectedImage({
            fileName,
            url: `${SERVER_ADDRESS}/img/${product.id}/${fileName}`
        });
    }, [, product.pictures, selectedImage, setSelectedImage]);

    // Change photo back function using useCallback for memoization
    const back = useCallback(() => {
        const length = product.pictures.length;
        const index = product.pictures.findIndex(el => el === selectedImage.fileName);
        const fileName = (index === 0) ? product.pictures[length - 1] : product.pictures[index - 1];

        setSelectedImage({
            fileName,
            url: `${SERVER_ADDRESS}/img/${product.id}/${fileName}`
        });
    }, [product.pictures, selectedImage, setSelectedImage]);

    useEffect(() => {
        noBackgroundScroll("add")
        window.addEventListener('keydown', (e) => productPreviewHandleKeyDown(e, closeModal, forward, back));

        // Cleanup effect on component unmount
        return () => {
            noBackgroundScroll("remove")
            window.removeEventListener('keydown', (e) => productPreviewHandleKeyDown(e, closeModal, forward, back));
        };
    }, []);

    // Position loaded photo scroll in the middle
    useEffect(() => {
        const container = document.querySelector(".image-container");
        if (container) {
            setScrollBarInMiddle(container, "")
        }
    }, [zoomIn]);

    const onZoomInClicked = () => {
        setZoomIn(prev => !prev)
    }

    return (
        <div className="overlay" onClick={closeModal}>
            <div className="preview__content col-18 col-md-16" onClick={(e) => e.stopPropagation()}>
                <div
                    ref={containerRef}
                    className="preview__content__image__container image-container"
                    tabIndex="-1"
                >
                    <div className="preview__content__image__container-buttons">
                        <button className="nav-button back" onClick={back}><Back/></button>
                        <button className="nav-button close" onClick={closeModal}><Exit/></button>
                        <button className="nav-button forward" onClick={forward}><Forward/></button>
                    </div>

                    <img
                        src={selectedImage.url}
                        alt="Preview"
                        className={zoomIn
                            ? "preview__content__image__container-image zoom-in"
                            : "preview__content__image__container-image"}
                        onLoad={() => setScrollBarInMiddle(containerRef.current, "show")}
                        onClick={onZoomInClicked}
                        tabIndex="-1"
                    />
                </div>
            </div>
        </div>
    );
};


export default PicturePreview;
