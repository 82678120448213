import React from 'react';
import {ReactComponent as Garbage} from "../../images/garbage.svg";

const ChangeProductQuantities = ({quantity, availableQuantity, increase, decrease, handleRemoveProduct}) => {
    return (
        <div className='quantities'>
            <div className='quantities__quantity'>
                <button className='quantities__quantity-decrease' onClick={decrease}>-</button>
                <span>{quantity}</span>
                <button
                    className={availableQuantity > 0 ? 'quantities__quantity-increase' : 'quantities__quantity-increase-disabled'}
                    onClick={increase}
                    disabled={availableQuantity <= 0}>
                    +
                </button>

            </div>
            <button
                onClick={handleRemoveProduct}
                className='quantities-remove'>
                <Garbage/>
            </button>
        </div>
    );
};

export default ChangeProductQuantities;