import React, {useEffect, useState} from 'react';
import {useGetClientQuery} from "../clientApiSlice";
import useAuth from "../../../hooks/useAuth";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import ClientOrders from "./ClientOrders";
import ClientData from "./ClientData";
import ClientPanelHeader from "./ClientPanelHeader";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const ClientPanel = () => {
    const {email} = useAuth();
    const [err, setErr] = useState(null);
    const {
        data: client,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientQuery(email, {
        skip: !email, // Skip the query if email is empty
        // refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    const result = (
        <>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            {isLoading && <h1>Loading...</h1>}
            {isSuccess && <>
                <ClientPanelHeader client={client}/>
                <div className="row">
                    <ClientOrders orders={client.orders}/>
                    <ClientData client={client}/>
                </div>
            </>}
        </>
    );

    return result

};

export default ClientPanel;
