import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import store from "../../app/store";
import {productsApiSlice} from "../Products/producsApiSlice";
import {clientApiSlice} from "../Clients/clientApiSlice";
import {ordersApiSlice} from "../Orders/orderApiSlice";

const VerifyAdmin = () => {
    const location = useLocation()
    const {isAdmin} = useAuth()

    useEffect(() => {
        const prefetchProducts = async () => {
            console.log("prefetch");
            try {
                await store.dispatch(
                    ordersApiSlice.util.prefetch('getOrders', 'ordersList', {force: true})
                );

                await store.dispatch(
                    productsApiSlice.util.prefetch('getProducts', 'productsList', {force: true})
                );

                await store.dispatch(
                    clientApiSlice.util.prefetch('getClients', 'clientList', {force: true})
                );

            } catch (error) {
                console.error("Prefetch failed...", error);
            }
        };

        if (isAdmin) prefetchProducts();
    }, []);
    if (isAdmin) {
        return <Outlet/>
    } else {
        return <Navigate to="/login" state={{from: location}} replace/>
    }
};

export default VerifyAdmin;