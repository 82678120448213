import React from 'react';

const CheckBox = ({name, className = "", label = null, checked, setChecked}) => {
    return (
        <div className={`checkbox ${className}`}>
            <input
                className='checkbox-input'
                type="checkbox"
                name={name}
                id={name}
                data-id={name}
                checked={checked}
                onChange={(e) => setChecked(e)}
            />
            <span className="custom-checkbox"></span>
            <label
                className={'checkbox-label'}
                htmlFor={name}
            >{label}</label>
        </div>
    );
};

export default CheckBox;