import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetOrderByIdQuery,
    useInitiatePaymentMutation,
} from "../orderApiSlice";
import SummaryItems from "./SummaryItems";
import SummaryCart from "./SummaryCart";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const OrderSummary = () => {
    const {id} = useParams();
    const [newOrder, setNewOrder] = useState(null);
    const [err, setErr] = useState()
    const navigate = useNavigate()
    const {
        data: order,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetOrderByIdQuery(id, {
        skip: !id, // Skip the query if email is empty
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [initiatePayment, {
        data,
        isLoading: isPaymentLoading,
        isSuccess: isPaymentSuccess,
        isError: isPaymentError,
        error: paymentError
    }] = useInitiatePaymentMutation();

    useEffect(() => {
        if (order) setNewOrder(order);
    }, [order]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(getErrorMessage(error)));
        if (isPaymentError) setErr(getErrorMessage(paymentError))
    }, [error, isError, paymentError, isPaymentError]);

    const clearError = () => {
        setErr(null);
    };

    // Handler to toggle payment completion status
    const onMakePaymentClicked = async () => {

        const paymentObject = {
            id: newOrder.id,
            amount: newOrder.payment.total,
            email: newOrder.email,
            client: `${newOrder.name} ${newOrder.surname}`,
            country: newOrder.country.code,
            language: newOrder.country.language,
            number: newOrder.number
        }

        try {
            const {url} = await initiatePayment({...paymentObject}).unwrap()
            window.location.replace(url);
        } catch (err) {
            console.error(getErrorMessage(err))
        }
    };

    // Handler to toggle subscription status
    const onSubscribeClicked = () => {
        setNewOrder(prev => ({
            ...prev,
            subscribe: !prev.subscribe
        }));
    };

    // Render the order summary section
    return (
        <section className='inner-section order'>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            {isSuccess && newOrder && <div className='row order__content'>
                <SummaryItems
                    newOrder={newOrder}
                    onMakePaymentClicked={onMakePaymentClicked}
                    onSubscribeClicked={onSubscribeClicked}
                />
                <SummaryCart
                    payment={newOrder?.payment}
                    products={newOrder?.products}
                />
            </div>}
        </section>
    );
};

export default OrderSummary;
