import React, {useEffect, useState} from 'react';

const ErrorPopup = ({message, onClose}) => {
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const fadeTimer = setTimeout(() => {
            setIsFading(true);
        }, 4500); // Adjust to slightly less than the total delay

        const removeTimer = setTimeout(() => {
            onClose();
        }, 5000); // Total delay before removal

        return () => {
            clearTimeout(fadeTimer);
            clearTimeout(removeTimer);
        };
    }, [onClose]);

    return (
        <div className={`error-popup ${isFading ? 'fade-out' : ''}`}>
            {message}
        </div>
    );
};

export default ErrorPopup;
