import {ReactComponent as Close} from '../../../images/close.svg';
import {Link} from "react-router-dom";
import {useEffect} from "react";
import {noBackgroundScroll} from "../../../utils/noBackgroundScroll";

const ProductViewAddedProduct = ({addedProduct, setAddedProduct}) => {
    useEffect(() => {
        noBackgroundScroll("add")
        // Cleanup the effect when the component is unmounted
        return () => {
            noBackgroundScroll("remove");
        };
    }, []);

    return (
        <div className="overlay">
            <div className="added__product col-16 col-md-12 col-lg-10">
                <div className="added__product-header">
                    <h3>DODANO DO KOSZYKA</h3>
                    <button
                        className="close-button"
                        onClick={() => setAddedProduct(null)}
                    ><Close/></button>
                </div>
                <div className="added__product__content">
                    <div className="added__product__content-picture  col-5">
                        <img className='product__picture'
                             src={addedProduct.image}
                             alt={`${addedProduct.kind} - ${addedProduct.name}`}/>
                    </div>
                    <div className="added__product__content-details col-13">
                        <h4 className="detail detail-header">{addedProduct.name}</h4>
                        <p className="detail detail-text"><span>{addedProduct.kind}</span></p>
                        {addedProduct.color &&
                            <p className="detail detail-text">Kolor: <span>{addedProduct.color} </span></p>}
                        {addedProduct.size &&
                            <p className="detail detail-text">Rozmiar: <span>{addedProduct.size} </span></p>}
                        {addedProduct.amount &&
                            <p className="detail detail-text">Kwota: <span>{addedProduct.amount} </span></p>}
                    </div>
                </div>
                <div className="added__product__buttons">
                    <Link
                        className="link-button"
                        to="/shop"
                    >WRÓĆ DO SKLEPU</Link>
                    <Link
                        className="link-button"
                        to="/cart"
                    >PRZEJDŹ DO KOSZYKA</Link>
                </div>
            </div>
        </div>
    );
};

export default ProductViewAddedProduct;