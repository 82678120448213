import React from "react";

const ProductPrice = ({product}) => {
    const {
        price,
        promotion: {isPromotion, basePrice},
        lowestPriceInThirtyDays: {lowestPrice}
    } = product
    const priceElement = isPromotion
        ? <>
            <span className="price">{price} PLN</span>
            <span className="base-price">{basePrice} PLN</span>
        </> :
        `${product.price} PLN`

    const lowestPriceElement = isPromotion
        ? <p className='product__price-lowest'>Najniższa cena z ostatnich 30 dni: {lowestPrice} PLN</p>
        : null

    return <>
        <p className='product__price__tag'>{priceElement}</p>
        {lowestPriceElement}
    </>
}

export default ProductPrice