import React, {useEffect, useState} from "react";
import CartProduct from '../../Cart/CartProduct'
import {useSelector} from "react-redux";
import {useGetDiscountQuery} from "../../Discounts/discountApiSlice";
import CartProductGiftCard from "../../Cart/CartProductGiftCard";
import useConfig from "../../../hooks/useConfig";
import {getPaymentAmounts} from "../../../utils/getPaymentAmounts";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const OrderCart = ({order, setOrder, setErr}) => {
    const [notFound, setNotFound] = useState(null)
    const {CONSTANTS: {SHIPMENT_OPTIONS}} = useConfig()
    const {code, amount} = order.payment.discount
    const {totalCartItems, items} = useSelector((state) => state.cart.cart);
    const {REG_EX} = useConfig()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDiscountQuery(code, {
        skip: !code && !amount
    })

    useEffect(() => {
        if (data && isSuccess) {
            const {type, amount, name, reusable} = data
            setOrder(prev => ({
                ...prev,
                payment: {
                    ...prev.payment,
                    discount: {
                        ...prev.payment.discount,
                        amount: type === "procent" ? amount / 100 : amount,
                        type,
                        name,
                        reusable
                    }
                }
            }));
        }
        if (isError) {
            error.status === 404
                ? setNotFound("Nie odnaleziono.")
                : getErrorMessage(error);

            setOrder(prev => ({
                ...prev,
                payment: {
                    ...prev.payment,
                    discount: {
                        amount: 0,
                        type: "",
                        name: "",
                    }
                }
            }));
        }
    }, [data, isSuccess, error, isError]);

    useEffect(() => {
        setOrder(prev => getPaymentAmounts({...prev, products: items}, SHIPMENT_OPTIONS));
    }, [items, order.products, order.shipment, order.payment.discount, setOrder]);

    const onAddDiscountClicked = (e) => {
        e.preventDefault()
        const code = e.target.discount.value;
        if (!code) return
        if (!REG_EX.GIFT_CARD_CODE.test(code)) {
            setNotFound("Niepoprawny kod")
            return;
        }
        setOrder(prev => ({
            ...prev,
            payment: {
                ...prev.payment,
                discount: {
                    ...prev.payment.discount,
                    code
                }
            }
        }))
        e.target.discount.value = ""
    }
    if (totalCartItems) {
        const productsList = items.map(cartItem => cartItem.name === "Karta podarunkowa"
            ? <CartProductGiftCard
                key={`${cartItem.id}-${cartItem.size}`}
                cartItem={cartItem}
                styling={["col-8", "col-10"]}
            />
            : <CartProduct
                key={`${cartItem.id}-${cartItem.size}`}
                cartItem={cartItem}
                styling={["col-8", "col-10"]}
            />
        );
        const giftCardCounter = order.products.reduce((acc, product) => {
            if (product.name === "Karta podarunkowa") {
                return acc + product.quantity
            }
            return acc
        }, 0)

        const result = (
            <div className="col-18 col-lg-8 order__content__cart">
                <h2 className="cart__content-title">Koszyk</h2>
                <div className="cart__content-products">
                    {productsList}
                </div>
                <form
                    className="order__content__cart-discount"
                    onSubmit={onAddDiscountClicked}>
                    <div className="input-group">
                        <input
                            className="discount-input white-button"
                            name="discount"
                            type='text'
                            placeholder='KOD RABATOWY LUB Karta podarunkowa'
                            onChange={() => setNotFound(null)}

                        />
                        {isLoading && <span className="discount-success">Loading...</span>}
                        {isSuccess && <span
                            className="discount-success">{`Kod ${order.payment.discount.code} został pomyślnie zastosowany`}</span>}
                        <span className='error-message'>{notFound}</span>

                    </div>
                    <button
                        className="discount-button link-button"
                        type="submit"
                    >ZASTOSUJ
                    </button>
                </form>

                <div className='summary'>
                    {order.payment.products
                        ? <div className='summary__group'>
                            <span className='summary__group-title'>Koszt produktów: </span>
                            <span className='summary__group-subtotal'>{order.payment.products} PLN</span>
                        </div>
                        : null}
                    {isSuccess && <div className='summary__group'>
                        <span
                            className='summary__group-title'>{order.payment.discount.type === "procent"
                            ? `${order.payment.discount.name} ${order.payment.discount.amount * 100}%`
                            : order.payment.discount.name}</span>
                        <span
                            className='summary__group-subtotal'>{
                            order.payment.products && order.payment.discountAmount || order.payment.isEligible
                                ? `-${order.payment.discountAmount} PLN`
                                : "nie dotyczy"
                        }</span>
                    </div>}
                    {!!order.payment.giftCards && <div className='summary__group'>
                        <span
                            className='summary__group-title'>{giftCardCounter > 1 ? "Karty podarunkowe" : "Karta podarunkowa"}</span>
                        <span
                            className='summary__group-subtotal'>{order.payment.giftCards} PLN</span>
                    </div>}

                    <div className='summary__group summary__group'>
                        <span className='summary__group-title'>Wysyłka: </span>
                        <span
                            className='summary__group-shipmnet'>{order.payment.shipment ? `${order.payment.shipment} PLN` : 'Wypełnij dane o dostawie'}</span>
                    </div>
                    <div className='summary__group'>
                        <div className="summary__group-total">
                            <span className="total-title">Suma: </span>
                            <span
                                className="total-sum">{Number(order.payment.total)} PLN</span>
                        </div>
                        {/*<span>W tym podatek VAT: {order.payment.vatTax} PLN</span>*/}
                    </div>
                </div>
            </div>
        );
        return result
    } else {
        return <h1>Koszyk jest pusty</h1>
    }
};

export default OrderCart;
