import React from 'react';
import {Link} from "react-router-dom";

const ClientData = ({client}) => {
    return (
        <div className="col-18 col-lg-8 client__panel__content client__data">
            <h3 className="client__panel__content-title client__data-title">ADRESY I DANE</h3>
            <p className="client__data-item">{client.name} {client.surname}</p>
            <p className="client__data-item">{client.addresses.country.name}</p>
            <p className="client__data-item">{client.addresses.street}</p>
            <p className="client__data-item">{client.addresses.postCode}, {client.addresses.city}</p>
            <p className="client__data-item phone">{client.phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3')}</p>
            <p className="client__data-item">{client.email}</p>
            <Link to={`edit`} className="link-button">ZARZĄDZAJ KONTEM</Link>
        </div>
    );
};

export default ClientData;