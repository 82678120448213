import React from "react";
import validateOrderData from "../../../utils/validateOrderData";
import useConfig from "../../../hooks/useConfig";

const BillingAddressSection = ({order, setOrder}) => {
    const CONFIG = useConfig()
    const isVisible = validateOrderData(order, CONFIG).data
    const handleChange = (e) => {
        setOrder(prev => ({
            ...prev,
            billingAddress: {
                ...order.billingAddress,
                theSame: !order.billingAddress.theSame
            }
        }))
    }

    return (
        <div className="form-section">
            <h2 className="form-subtitle">Dane rozliczeniowe</h2>

            {isVisible && <div>
                <div
                    className="radio-element ">
                    <input
                        checked={order.billingAddress.theSame}
                        onChange={handleChange}
                        type="radio"
                        name="addressGroup"
                        id="theSame"/>
                    <label
                        className="radio-label"
                        htmlFor="theSame">
                        Takie same jak dane dostawy</label>
                </div>
                <div className="radio-element ">
                    <input
                        checked={!order.billingAddress.theSame}
                        onChange={handleChange}
                        type="radio"
                        name="addressGroup"
                        id="different"
                    />
                    <label
                        className="radio-label"
                        htmlFor="different">
                        Użyj innych danych</label>
                </div>
            </div>}
        </div>
    );
};

export default BillingAddressSection;