import {Outlet} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useRefreshMutation} from "./authApiSlice";
import {useSelector} from "react-redux";
import {selectCurrentToken} from "./authSlice";
import useSid from "../../hooks/useSid";
import {getErrorMessage} from "../../utils/getErrorMessage";
import Loading from "../LoadingPage/Loading";

const PersistLogin = () => {
    useSid();
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)
    const [trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()

    //Because of the strict mode
    useEffect(() => {
        if (effectRan.current === true || process.env.NODE_ENV !== "development") {
            const persist = JSON.parse(localStorage.getItem('persist')) || false
            const verifyRefreshToken = async () => {
                console.log('Verifying refresh token');
                try {
                    await refresh()
                    setTrueSuccess(true)
                } catch (err) {
                    setTrueSuccess(true)
                    console.log(err);
                }
            }
            if (!token && !persist) setTrueSuccess(true)
            if (!token && persist) {
                verifyRefreshToken()
            }
        }
        return () => effectRan.current = true //useRef will hold value when component is remounted in strict mode
    }, [token, refresh])

    if (isError) (getErrorMessage(error));
    if (isLoading) return <Loading/>
    if (trueSuccess) return <Outlet/>
};

export default PersistLogin;

