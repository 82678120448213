import React from 'react';
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useSendLogoutMutation} from "../Auth/authApiSlice";


const AdminHeader = () => {
    const navigate = useNavigate()
    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()
    const onLogoutClicked = async () => {
        await sendLogout()
    }
    const result = (
        <header className="admin__header">
            <NavLink
                className="admin__header__item link"
                to="/shop"
            >SKLEP</NavLink>

            <button
                className="admin__header__item blue-button"
                onClick={onLogoutClicked}
            >WYLOGUJ
            </button>
        </header>
    );
    return result
};

export default AdminHeader;