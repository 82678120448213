import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import useSid from "../../../hooks/useSid";
import useConfig from "../../../hooks/useConfig";

const SummaryItems = ({newOrder, onMakePaymentClicked, onSubscribeClicked}) => {
    // Get the showOrderData state from the Redux store
    const sid = useSid()
    const [showOrderData, setShowOrderData] = useState(false)
    const {CONSTANTS: {PAYMENT_STATUS_TRANSLATION}} = useConfig()

    useEffect(() => {
        if (sid) {
            setShowOrderData(newOrder.sid === sid);
        }
    }, [sid]);

    // Local state to manage input data for email and order number
    const [data, setData] = useState({email: "", orderNumber: ""});

    const onShowDataClicked = () => {
        const {email, number} = newOrder;
        if (email === data.email && number === Number(data.orderNumber)) {
            setShowOrderData(true);
        }
    };

    const isPaymentProcessed = newOrder?.payment?.status !== "pending"
        ? (
            <>
                <h3 className="order__summary__item-title">Status płatności</h3>
                <p className="order__summary__item-text">{PAYMENT_STATUS_TRANSLATION[newOrder.payment.status]}</p>
            </>
        ) : (
            <>
                <h3 className="order__summary__item-title">Twoje zamówienie nie jest opłacone</h3>
                <p className="order__summary__item-text">Opłać swoje zamówienie</p>
            </>
        );

    // Conditionally render order data if showOrderData is true
    const ifShowOrderData = showOrderData
        ? (
            <>
                <h2 className="order__summary-title">Zamówienie nr: {newOrder?.number}</h2>
                <p className="order__summary-text">Dziekujemy {newOrder?.name}!</p>
            </>
        ) : (
            <div className="order__summary__item">
                <h3 className="order__summary__item-title">Podaj dane zamówienia aby wyświetlić szczegóły</h3>
                <p className="order__summary__item-text">Numer zamówienia znajdziesz w potwierdzeniu wysłanym
                    e-mailem</p>
                <input
                    className='order__summary__item-input'
                    type='email'
                    name='email'
                    placeholder="E-mail"
                    value={data.email}
                    onChange={(e) => setData(prev => (
                        {...prev, email: e.target.value}))}
                />
                <input
                    className='order__summary__item-input'
                    type="text"
                    name="orderNumber"
                    placeholder="Numer zamówienia"
                    value={data.orderNumber}
                    onChange={(e) => setData(prev => (
                        {...prev, orderNumber: e.target.value}))}
                />
                <button
                    className='link-button'
                    onClick={onShowDataClicked}>
                    WYŚWIETL SZCZEGÓŁY
                </button>
            </div>
        );

    // Render the summary items component
    return (
        <div className="col-18 col-lg-8 order__summary">
            {ifShowOrderData}
            {newOrder?.payment?.status === "pending"
                ? <div className="order__summary__item">
                    <h3 className="order__summary__item-title">Płatność</h3>
                    <p className="order__summary__item-text">Zapłać za pomocą: {newOrder?.payment.method}</p>
                    <button
                        className='link-button payman'
                        onClick={onMakePaymentClicked}
                    >
                        ZAPŁAĆ
                    </button>
                </div>
                : null}

            <div className="order__summary__item completed">
                {isPaymentProcessed}
            </div>

            {showOrderData && <div className="order__summary__item">
                <h3 className="order__summary__item-title">Szczegóły zamówienia</h3>
                <div className='row'>
                    <div className='col-8'>
                        <h3 className='order__summary__item-text first'>Dane kontaktowe</h3>
                        <p className='order__summary__item-detail'>
                            {newOrder?.email}
                        </p>
                        <h3 className='order__summary__item-text'>Adres wysyłki</h3>
                        <p className='order__summary__item-detail'>
                            {newOrder?.name} {newOrder?.surname}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.street}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.postCode} {newOrder?.city}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.country?.name}
                        </p>
                        <p className='order__summary__item-detail'>
                            {newOrder?.phone}
                        </p>
                        <h3 className='order__summary__item-text'>Metoda wysyłki</h3>
                    </div>
                    <div className='col-8'>
                        <h3 className='order__summary__item-text first'>Metoda płatności</h3>
                        <p className='order__summary__item-detail'>
                            {newOrder?.payment.method} <span> - {newOrder?.payment.total} PLN</span>
                        </p>
                        <h3 className='order__summary__item-text'>Adres rozliczeniowy</h3>
                        {newOrder?.billingAddress?.theSame
                            ? <p className='order__summary__item-detail'>
                                Taki sam jak adres wysyłki
                            </p>
                            : <>
                                <h3 className='order__summary__item-text'>Adres wysyłki</h3>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.name} {newOrder?.billingAddress.surname}
                                </p>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.street}
                                </p>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.postCode} {newOrder?.billingAddress.city}
                                </p>
                                <p className='order__summary__item-detail'>
                                    {newOrder?.billingAddress.country?.name}
                                </p>
                            </>}
                    </div>
                </div>
            </div>}
            {/*<div className="order__summary__item">*/}
            <Link to="/" className="link-button continue">KONTYNUUJ ZAKUPY</Link>
            {/*</div>*/}
        </div>
    );
};

export default SummaryItems;
