import React from "react";

const PanelSectionTextarea = ({isValid, check, value, setInput, title, placeholder, name}) => {
    return (
        <div className="admin__panel__section__from__section ">
            <span
                className="admin__panel__section__from__section-title col-18 col-md-4 col-lg-3 col-hd-2">{title}</span>
            <div className="admin__panel__section__from__section-inputs col-18 col-md-14 col-lg-15 col-hd-16">
                        <textarea
                            className={!check ? 'section-input col-18 col-md-12' : isValid ? 'section-input col-18 col-md-12' : 'section-input col-18 col-md-12 not-valid'}
                            name={name}
                            data-id={name}
                            placeholder={placeholder}
                            value={value}
                            onChange={setInput}>
                        </textarea>
            </div>
        </div>
    );
};

export default PanelSectionTextarea;