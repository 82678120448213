import React from 'react';
import {format} from 'date-fns';
import {Link} from "react-router-dom";
import useConfig from "../../../hooks/useConfig";

const ClientPanelOrderCard = ({order}) => {
    const {_id} = order
    const date = new Date(order.createdAt);
    const formattedDate = format(date, 'dd.MM.yyyy');
    const {CONSTANTS: {ORDER_STATUS_TRANSLATION}} = useConfig()


    if (!order) return null
    const status = ORDER_STATUS_TRANSLATION[order.status.toUpperCase()]
    
    return (
        <div className='order__card'>
            <p className='item order__card-date'>{formattedDate}</p>
            <p className='item order__card-total'>{order.payment.total} PLN</p>
            <p className='item order__card-number'>Nr zamówienia: {order.number}</p>
            <p className='item order__card-status'>{status?.toUpperCase()}</p>
            <Link
                to={`order_view/${_id}`}
                className='white-button'>
                ZOBACZ SZCZEGÓŁY
            </Link>
        </div>
    );
};

export default ClientPanelOrderCard;