import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {addItem, deleteItem, removeItem, selectCartError, updateCartAfterAction} from "./cartSlice";
import {useGetProductsQuery} from "../Products/producsApiSlice";
import ErrorPopup from "../../components/Reusable/ErrorPopup";
import ChangeProductQuantities from "../../components/Reusable/ChangeProductQuantities";
import {getErrorMessage} from "../../utils/getErrorMessage";
import useConfig from "../../hooks/useConfig";

const CartProductGiftCard = ({cartItem, styling}) => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const dispatch = useDispatch();
    const {id, color, size, price, quantity} = cartItem;
    const navigate = useNavigate();
    const {totalCartItems, items} = useSelector((state) => state.cart.cart);
    const [err, setErr] = useState(null)
    const cartError = useSelector(selectCartError);

    // Get the product data from Redux store using RTK Query
    const {product} = useGetProductsQuery("productList", {
        selectFromResult: ({data}) => ({
            product: data?.entities[id]
        }),
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (cartError) setErr(getErrorMessage(cartError));
    }, [cartError]);

    const clearError = () => {
        setErr(null);
    };

    // Increase the quantity of the item in the cart
    const increase = () => {
        dispatch(updateCartAfterAction(addItem({id, color, size})));
    };

    // Decrease the quantity of the item in the cart
    const decrease = () => {
        quantity > 1
            ? dispatch(updateCartAfterAction(removeItem({id, color, size})))
            : dispatch(updateCartAfterAction(deleteItem({id, color, size})))
    };

    // Handle removing the product from the cart
    const handleRemoveProduct = () => {
        dispatch(updateCartAfterAction(deleteItem({id, color, size})));
        if (!cartError && !totalCartItems) navigate("/");
    };

    if (!product) return null;
    const path = `${SERVER_ADDRESS}/img/${product.id}/${product.pictures[0]}`;

    return (
        <div className='row cart__product cart__product__gift__card'>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <div className={styling[0]}>
                <Link className={'cart__product-link'}
                      to={`/shop/gift_card/${product.id}`}>
                    <img className='cart__product-image'
                         src={path}
                         alt={product.name}/>
                </Link>
            </div>
            <div className={`${styling[1]} cart__product-info-content`}>
                <p className='cart__product-kind'> {product.kind}</p>
                <h2 className='cart__product-name'>{product.name}</h2>
                <p className='cart__product-price'>kwota</p>
                <p className='cart__product__price-tag'>{price} PLN</p>
                <p className='cart__product-quantity'>ilość: {quantity}</p>
                <ChangeProductQuantities
                    availableQuantity={10}
                    quantity={cartItem.quantity}
                    increase={increase}
                    decrease={decrease}
                    handleRemoveProduct={handleRemoveProduct}/>
            </div>
        </div>
    );
};

export default CartProductGiftCard;