import SaveButton from "../../../../components/Reusable/SaveButton";
import React, {useEffect, useState} from "react";
import ClientPanelHeader from "../ClientPanelHeader";
import {useGetClientQuery, useUpdateClientMutation} from "../../clientApiSlice";
import useAuth from "../../../../hooks/useAuth";
import {ReactComponent as Close} from '../../../../images/close.svg';
import {Link, useNavigate} from "react-router-dom";
import ErrorPopup from "../../../../components/Reusable/ErrorPopup";
import {validateNewPassword} from "../../../../utils/validateNewPassword";
import PasswordInput from "../../../../components/Reusable/PasswordInput";
import {getErrorMessage} from "../../../../utils/getErrorMessage";
import useConfig from "../../../../hooks/useConfig";

const PasswordChange = () => {
    const {email} = useAuth()
    const navigate = useNavigate()
    const [err, setErr] = useState(null)
    const [formError, setFormError] = useState("")
    const {REG_EX} = useConfig()
    const [check, setCheck] = useState()
    const [data, setData] = useState({
        password: "",
        newPassword: "",
        newPassword2: ""
    })
    const [isValid, setIsValid] = useState({
        password: false,
        newPassword: false,
        newPassword2: false,
        all: false
    })
    const {
        data: client,
        isLoading: isClientLoading,
        isSuccess: isClientSuccess,
        isError: isClientError,
        error: clientError
    } = useGetClientQuery(email, {
        skip: !email,
    });
    const [updateClient, {
        data: updatedClient,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateClientMutation();

    useEffect(() => {
        if (isSuccess) navigate('/account/edit')
    }, [updatedClient, isSuccess]);

    useEffect(() => {
        setFormError(null);
        setIsValid(validateNewPassword(data, REG_EX))
    }, [data]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isClientError) setErr(getErrorMessage(clientError));
    }, [error, isError, clientError, isClientError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        setCheck(false)
        let {value, dataset: {id}} = e.target;
        setData(prev => ({
            ...prev,
            [id]: value
        }))
    }

    const onSaveDataClicked = async (e) => {
        e.preventDefault()
        setCheck(true)
        if (!isValid.newPassword) {
            setFormError("Hasło musi mieć co najmniej 8 znaków, zawierać przynajmniej jedną małą i wielką literę, cyfrę oraz znak specjalny.")
            return
        }

        if (data.newPassword !== data.newPassword2) {
            setFormError("Hasła są niezgodne.")
            return;
        }

        if (!isValid.all) return

        try {
            await updateClient({
                id: client.id,
                ...data
            }).unwrap()
        } catch (err) {
            console.log(getErrorMessage(err));
        }
    }

    return (
        <div className="client__edit">
            <ClientPanelHeader client={client}/>
            <Link
                className="close-button"
                to={'/account/edit'}
            ><Close/></Link>
            <div className="col-18 client__panel__content">
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <form
                    className="client__edit__form col-18 col-lg-9"
                    onSubmit={onSaveDataClicked}>
                    <h2 className="client__edit__form-title">ZMIEŃ HASŁO</h2>

                    <PasswordInput
                        placeholder="Stare hasło"
                        handlePwdInput={setInput}
                        check={check}
                        isValid={isValid.password}
                    />
                    <PasswordInput
                        placeholder="Nowe hasło"
                        handlePwdInput={setInput}
                        name="newPassword"
                        dataId="newPassword"
                        check={check}
                        isValid={isValid.newPassword}
                    />
                    <PasswordInput
                        placeholder="Powtórz nowe hasło"
                        handlePwdInput={setInput}
                        name="newPassword2"
                        dataId="newPassword2"
                        check={check}
                        isValid={isValid.newPassword2}
                    />

                    <SaveButton
                        className="link-button"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                        errorMessage={formError}
                        text="ZAPISZ"
                        isLoadingText="ZAPISUJĘ..."
                    />
                </form>
            </div>
        </div>
    );
};

export default PasswordChange;
