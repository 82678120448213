import {ReactComponent as Logo} from "../../images/BeBrave_logo.svg";


const Loading = () => {
    return (
        <div className="loading">
            <h1 className="loading__title"><Logo/></h1>
        </div>
    );
};

export default Loading;