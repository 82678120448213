import React, {useState} from 'react';

const RemoveButton = ({onRemove, text, disabled}) => {
    const [display, setDisplay] = useState()

    const onRemoveClicked = () => {
        setDisplay(false)
        onRemove()
    }

    return (
        <div className="remove">
            {/*<button*/}
            {/*    className="remove__button admin-button"*/}
            {/*    onClick={() => setDisplay(true)}>*/}
            {/*    <span className="remove__button-decor"></span>*/}
            {/*    <span className="remove__button-decor"></span>*/}
            {/*</button>*/}
            <button
                className="remove__button-text"
                onClick={() => setDisplay(true)}
                disabled={disabled}
            >USUŃ
            </button>
            <div className={display ? "popup__confirmation" : "popup__confirmation-hide"}>
                <p className="popup__confirmation-text">
                    {text}
                </p>
                <div className="popup__confirmation-buttons">
                    <button
                        className="popup__confirmation-button yellow-button"
                        onClick={() => setDisplay(false)}
                    >Anuluj
                    </button>
                    <button
                        className="popup__confirmation-button yellow-button"
                        onClick={onRemoveClicked}
                    > Usuń
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RemoveButton;