import {ReactComponent as Logo} from "../../images/BeBrave_logo-1.svg";
import {ReactComponent as Cart} from "../../images/cart.svg";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import useAuth from "../../hooks/useAuth";
import {useSendLogoutMutation} from "../Auth/authApiSlice";
import {useEffect, useRef, useState} from "react";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false)
    const menuRef = useRef(null);
    const {totalCartItems} = useSelector((state) => state.cart.cart);
    const {isLoggedIn, isAdmin} = useAuth()
    const navigate = useNavigate()
    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    const onLogOutClicked = async () => {
        await sendLogout()
        toggleShowMenu()
    }
    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isSuccess) {
            navigate("/")
        }
    }, [isSuccess]);

    const toggleShowMenu = () => {
        if (window.innerWidth >= 1024) return;
        setShowMenu(prev => !prev)
    }
    // Close menu if clicking outside
    const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setShowMenu(false);
        }
    };

    const cartLink = totalCartItems
        ? <NavLink
            className="header__nav-item "
            to="/cart"
            onClick={() => {
                if (showMenu) setShowMenu(false)
            }}>
            <span className="basket-text">KOSZYK</span>
            <Cart className="cart-icon"/>
            {totalCartItems > 0 && <span className='cart-count'>{totalCartItems}</span>}
        </NavLink>
        : <p className="header__nav-item " to="/cart">
            <span className="basket-text">KOSZYK</span>
            <Cart className="cart-icon"/>
            {totalCartItems > 0 && <span className='cart-count'>{totalCartItems}</span>}
        </p>

    const result = (
        <header
            className="header"
            ref={menuRef}
        >
            <Link
                className='header__logo'
                to="/"
                onClick={() => {
                    if (showMenu) setShowMenu(false)
                }}
            >
                <Logo/>
            </Link>
            <nav className="header__nav">
                <input
                    type="checkbox"
                    className="header__nav__btn"
                    id="header__nav__btn"
                    checked={showMenu}
                    onChange={toggleShowMenu}
                />
                <label
                    htmlFor="header__nav__btn"
                    className="header__nav-label"
                >
                        <span className="header__nav__btn-decor">
                        </span>
                </label>
                <div
                    className="header__nav__parts"
                >
                    <div className='header__nav__parts-main'>
                        <NavLink
                            className="header__nav-item"
                            to="/shop"
                            onClick={toggleShowMenu}
                        >SKLEP</NavLink>
                        <NavLink
                            className="header__nav-item"
                            to="/about"
                            onClick={toggleShowMenu}
                        >O NAS</NavLink>
                        <NavLink
                            className="header__nav-item" to="/contact"
                            onClick={toggleShowMenu}
                        >KONTAKT</NavLink>
                    </div>
                    <div className='header__nav__parts-side'>
                        {isLoggedIn && <button
                            className="header__nav-item logout"
                            onClick={onLogOutClicked}
                        >WYLOGUJ</button>}

                        {isAdmin && <NavLink
                            className="header__nav-item"
                            to="/admin"
                            onClick={toggleShowMenu}
                        >ADMIN</NavLink>}

                        <NavLink
                            className="header__nav-item"
                            to={isLoggedIn
                                ? isAdmin
                                    ? "/account"
                                    : "/account" : "/login"}
                            onClick={toggleShowMenu}
                        >KONTO</NavLink>

                    </div>
                </div>
                <div className='header__nav__parts-cart'>
                    {cartLink}
                </div>
            </nav>
        </header>
    );
    return result
};

export default Header;