import React from 'react';
import InnerSection from "../../../components/Reusable/InnerSection";
import {Outlet} from "react-router-dom";

const ClientPanelLayout = () => {
    return (
        <InnerSection>
            <div className='client__panel'>
                <Outlet/>
            </div>
        </InnerSection>
    );
};

export default ClientPanelLayout;