import React from 'react';
import AdminEditMarkdown from "../Markdown/AdminEditMarkdown";

const AdminEditShipmentAndCosts = () => {
    return <AdminEditMarkdown
        title="Koszty i metody dostawy"
        navigateAfter="/consts_and_shipping"
        fileName="shipment_and_costs.md"
        className=""
    />;
};

export default AdminEditShipmentAndCosts;