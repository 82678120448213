import React from 'react';
import AdminEditMarkdown from "../Markdown/AdminEditMarkdown";

const AdminEditReturns = () => {
    return <AdminEditMarkdown
        fileName="returns.md"
        navigateAfter="/returns"
        title="Zwroty i wymiana"
        className="returns__content-body"
    />
};

export default AdminEditReturns;