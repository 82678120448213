import React from 'react';

const ClientPanelHeader = ({client}) => {
    return (
        <div className="client__panel__header">
            <h2 className='client__panel__header-title'>MOJE KONTO</h2>
            <p className='client__panel__header-welcome'>Dzień dobry, {client?.name}!</p>
        </div>
    );
};

export default ClientPanelHeader;