import {useState, useEffect} from 'react';

function useLocalStorage(key, initialValue) {
    // Get the stored value from localStorage
    const getStoredValue = () => {
        try {
            const storedValue = localStorage.getItem(key);
            return storedValue ? JSON.parse(storedValue) : initialValue;
        } catch (error) {
            console.error(`Error reading localStorage: ${key} , ${error}`);
            return initialValue;
        }
    };

    const [storedValue, setStoredValue] = useState(getStoredValue);

    // Update localStorage whenever the storedValue changes
    useEffect(() => {
        try {
            localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            console.error(`Error reading localStorage: ${key} , ${error}`);
        }
    }, [key, storedValue]);

    // Return the stored value and a function to update it
    const setValue = (value) => {
        setStoredValue(value);
    };

    return [storedValue, setValue];
}

export default useLocalStorage;
