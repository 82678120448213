import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";


const discountsAdapter = createEntityAdapter({});

const initialState = discountsAdapter.getInitialState();

export const discountApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDiscount: builder.query({
            query: (code) => ({
                url: `/api/discounts/${code}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
        }),
        getDiscounts: builder.query({
            query: () => ({
                url: '/api/admin/discounts',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            transformResponse: responseData => {
                const loadedDiscounts = responseData.map(discount => {
                    discount.id = discount._id;
                    return discount;
                });
                return discountsAdapter.setAll(initialState, loadedDiscounts);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Discount', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Discount', id}))
                    ];
                } else return [{type: 'Discount', id: 'LIST'}];
            }
        }),
        addNewDiscount: builder.mutation({
            query: (initialDiscountData) => ({
                url: '/api/admin/discounts',
                method: 'POST',
                body: {
                    ...initialDiscountData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Discount', id: arg.id},
            ],
        }),
        updateDiscount: builder.mutation({
            query: (initialDiscountData) => ({
                url: '/api/admin/discounts',
                method: 'PATCH',
                body: {
                    ...initialDiscountData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Discount', id: arg.id},
            ],
        }),
        deleteDiscount: builder.mutation({
            query: (id) => ({
                url: '/api/admin/discounts',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Discount', id: arg.id}
            ],
        })
    })
});

export const {
    useGetDiscountQuery,
    useGetDiscountsQuery,
    useUpdateDiscountMutation,
    useAddNewDiscountMutation,
    useDeleteDiscountMutation,
} = discountApiSlice;

// returns the query object
export const selectDiscountsResult = discountApiSlice.endpoints.getDiscounts.select();
// returns the query object

// creates memoized selector
const selectDiscountsData = createSelector(
    selectDiscountsResult,
    discountsResult => discountsResult.data // normalized state object with ids & entities
);

export const {
    selectAll: selectAllDiscounts,
    selectById: selectDiscountById,
    selectIds: selectDiscountIds
} = discountsAdapter.getSelectors(state => selectDiscountsData(state) ?? initialState);
