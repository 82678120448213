import React from 'react';
import CustomSelect from "../../../components/Reusable/CustomSelect";

const PanelSectionSelect = ({isValid, check, title, selected, setState, attribute, attributeId, placeholder, list}) => {
    return (
        <div className="admin__panel__section__from__section">
            <span
                className="admin__panel__section__from__section-title col-18 col-md-4 col-lg-3 col-hd-2">{title}</span>
            <div className="admin__panel__section__from__section-inputs col-18 col-md-14 col-lg-15 col-hd-16">
                <CustomSelect
                    placeholder={placeholder}
                    attribute={attribute}
                    attributeId={attributeId}
                    selected={selected}
                    setSelected={setState}
                    list={list}
                    className={!check ? 'section-input col-18 col-md-12' : isValid ? 'section-input col-18 col-md-12' : 'section-input col-18 col-md-12 not-valid'}
                />
            </div>
        </div>
    );
};

export default PanelSectionSelect;