import React, {useState} from 'react';

const AdminEditProductAddColor = ({isValid, check, state, setInput}) => {
    const [data, setData] = useState({
        color: "",
        sizeQuantity: ""
    })
    const [formError, setFormError] = useState(null)


    const onColorChange = (e) => {
        setFormError(null)
        const dataId = e.target.dataset.id;
        let {value} = e.target
        if (e.target.type === "number") value = Number(value)

        setData(prev => ({
            ...prev,
            [dataId]: value
        }))
    }

    const onAddColorClicked = () => {

        if (!data.color) {
            setFormError("Wprowadź kolor")
            return
        }

        if (!data.sizeQuantity) {
            setFormError("Wprowadż rozmiar i ilość w formacie ROZMIAR:ILOŚĆ - np: L:30.")
            return
        }

        const [size, quantity] = data.sizeQuantity.split(":")
        if (Number(quantity) < 0 || isNaN(Number(quantity)) || quantity === "") {
            setFormError("Brak wrowadzonej ilości lub ilość nie jest liczbą wieksza lub równą 0.")
            return;
        }

        setInput(prev => {
            let index = prev.quantities.colors.findIndex(el => el.color === data.color && Object.values(el).includes(size))
            if (index < 0) index = prev.quantities.colors.length
            let colors = [...prev.quantities.colors]

            const newSizeQuantity = {
                color: data.color,
                size,
                quantity: Number(quantity)
            }
            colors[index] = ({...newSizeQuantity})

            colors = colors.sort((a, b) => {
                if (a.color < b.color) return -1;
                if (a.color > b.color) return 1;
                return 0;
            })

            if (!prev.quantities.list.includes(newSizeQuantity.color)) {
                return {
                    ...prev,
                    quantities: {
                        list: [...prev.quantities.list, newSizeQuantity.color],
                        colors
                    }
                }
            } else {
                return {
                    ...prev,
                    quantities: {
                        ...prev.quantities,
                        colors
                    }
                }
            }
        })

        setData({
            color: "",
            sizeQuantity: ""
        })
    }

    const onRemoveColorClicked = (e) => {
        const {color} = e.target.dataset
        setInput(prev => {
            const {list, colors} = prev.quantities
            const newList = list.filter(el => el !== color)
            const newColors = colors.filter(el => el.color !== color)

            return {
                ...prev,
                quantities: {
                    list: newList,
                    colors: newColors
                }
            }
        })
    }
    const onSizeClicked = (el) => {
        const {color, size, quantity} = el
        setData({
            color,
            sizeQuantity: `${size}:${quantity}`
        });
    }
    const onClearSizeClicked = () => {
        setData({color: "", sizeQuantity: ""})
    }
    const onRemoveSizeClicked = () => {
        setInput(prev => {
            const [size, _] = data.sizeQuantity.split(":")
            let index = prev.quantities.colors.findIndex(el => el.color === data.color && Object.values(el).includes(size))
            if (index < 0) return prev
            console.log(index);

            const colors = [...prev.quantities.colors]
            colors.splice(index, 1)

            return {
                ...prev,
                quantities: {
                    ...prev.quantities,
                    colors
                }
            }
        })
        setData({color: "", sizeQuantity: ""})
    }

    let list = null
    if (state.quantities.colors) {
        list = state.quantities.list.map((color, even) => <div
            className={even % 2 === 0 ? "added__color col-18 col-md-12 odd" : "added__color col-18 col-md-12 even"}
            key={color}>
            <h3 className="added__color-title">{color}</h3>
            <div className="added__color__list">
                {(state.quantities.colors.filter((el) => el.color === color).map((el, i) => <div
                    key={`${el}-${i}`}
                    className="added__color__list-size"
                    onClick={() => onSizeClicked(el)}
                >
                    <span key={`${el.size}-${color}-${i}`}
                    >{el.size}: </span>
                    <span key={`${el.quantity}-${color}-${i}`}
                    >{el.quantity}</span>
                </div>))}
                <button
                    className="added__color-remove admin-button"
                    data-color={color}
                    type='button'
                    onClick={onRemoveColorClicked}
                >Usuń {color}
                </button>
            </div>
        </div>);
    }

    return (
        <div className="admin__panel__section__from__section add__color">
            <span
                className="admin__panel__section__from__section-title col-18 col-md-4 col-lg-3 col-hd-2">KOLOR</span>
            <div className="admin__panel__section__from__section-inputs col-18 col-md-14 col-lg-15 col-hd-16">
                <input
                    className={!check ? 'section-input col-18 col-md-12' : isValid ? 'section-input col-18 col-md-12' : 'section-input col-18 col-md-12 not-valid'}
                    type="text"
                    name="color"
                    data-id="color"
                    placeholder="Kolor"
                    value={data.color}
                    onChange={onColorChange}
                />

                <input
                    className={!check ? 'section-input col-18 col-md-12' : isValid ? 'section-input col-18 col-md-12' : 'section-input col-18 col-md-12 not-valid'}
                    type="text"
                    name="quantity"
                    data-id="sizeQuantity"
                    placeholder="[rozmiar:ilość]"
                    value={data.sizeQuantity}
                    onChange={onColorChange}
                />

                <span className='error-message'>{formError}</span>
                <div className='add__color__buttons'>
                    <button
                        className="add__color__buttons-button admin-button"
                        type="button"
                        onClick={onAddColorClicked}>
                        Zapisz
                    </button>
                    <button
                        className="add__color__buttons-button admin-button"
                        type="button"
                        onClick={onClearSizeClicked}>
                        Wyczyść
                    </button>
                    <button
                        className="add__color__buttons-button admin-button"
                        type="button"
                        onClick={onRemoveSizeClicked}
                        disabled={data.color === "" || data.sizeQuantity === ""}>
                        Usuń wybrany
                    </button>
                </div>
                {list}
            </div>
        </div>
    );
};

export default AdminEditProductAddColor;