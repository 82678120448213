export const MDE_OPTIONS = {
    toolbar: [
        "bold", "italic", "heading", "|", "quote", "code", "table", "preview", "side-by-side", "fullscreen", "|",
        {
            name: "new-paragraph",
            action: function customFunction(editor) {
                const cm = editor.codemirror;
                const selection = cm.getSelection();  // Get selected text
                const pos = cm.getCursor();
                if (selection) {
                    // Wrap selected text in <p> with left alignment
                    cm.replaceSelection(`<p>${selection}</p>`);
                } else {
                    // Insert new left-aligned paragraph if no selection
                    cm.replaceRange('<p>New left-aligned paragraph</p>', pos);
                }
            },
            className: "fa fa-paragraph",    // 6
            title: "Insert Paragraph",       // 7
        },

        {
            name: "align-left",
            action: function customFunction(editor) {
                const cm = editor.codemirror;
                const selection = cm.getSelection();  // Get selected text
                const pos = cm.getCursor();

                if (selection) {
                    // Wrap selected text in <p> with left alignment
                    cm.replaceSelection(`<p style="text-align:left;">${selection}</p>`);
                } else {
                    // Insert new left-aligned paragraph if no selection
                    cm.replaceRange('\n\n<p style="text-align:left;">New left-aligned paragraph</p>\n\n', pos);
                }
            },
            className: "fa fa-align-left", // FontAwesome left-align icon
            title: "Align Left",
        },
        {
            name: "align-right",
            action: function customFunction(editor) {
                const cm = editor.codemirror;
                const selection = cm.getSelection();  // Get selected text
                const pos = cm.getCursor();

                if (selection) {
                    // Wrap selected text in <p> with right alignment
                    cm.replaceSelection(`<p style="text-align:right;">${selection}</p>`);
                } else {
                    // Insert new right-aligned paragraph if no selection
                    cm.replaceRange('\n\n<p style="text-align:right;">New right-aligned paragraph</p>\n\n', pos);
                }
            },
            className: "fa fa-align-right", // FontAwesome right-align icon
            title: "Align Right",
        },
        {
            name: "align-justify",
            action: function customFunction(editor) {
                const cm = editor.codemirror;
                const selection = cm.getSelection();  // Get selected text
                const pos = cm.getCursor();

                if (selection) {
                    // Wrap selected text in <p> with justify alignment
                    cm.replaceSelection(`<p style="text-align:justify;">${selection}</p>`);
                } else {
                    // Insert new justified paragraph if no selection
                    cm.replaceRange('\n\n<p style="text-align:justify;">New justified paragraph</p>\n\n', pos);
                }
            },
            className: "fa fa-align-justify", // FontAwesome justify-align icon
            title: "Align Justify",
        }
    ]
};
