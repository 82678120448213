const PaymentSection = ({check, isValid, order, setOrder}) => {
    const isVisible = isValid.isShipmentValid
    const handleChange = (e) => {
        setOrder(prev => ({...prev, payment: {...prev.payment, method: e.target.id}}))
    }


    return (
        <div className="form-section">
            <h2 className="form-subtitle">Płatności</h2>
            {!isVisible && <div
                className={!check ? 'form-element' : isValid?.isPaymentValid ? 'form-element' : 'form-element not-valid'}
                tabIndex={0}
            >
                <span>Wprowadź dane dostawy, aby wybrać metodę płatności.</span>
                <span className={"select_decor"}></span>

            </div>}
            {isVisible && <>
                <div
                    className={!check ? 'radio-element col-18' : isValid?.isPaymentValid ? 'radio-element col-18' : 'radio-element col-18 not-valid'}>
                    <input
                        checked={order.payment.method === 'Przelewy24'}
                        type="radio"
                        name="paymentGroup"
                        id="Przelewy24"
                        onChange={handleChange}
                    />
                    <label
                        className="radio-label"
                        htmlFor="Przelewy24">
                        Przelewy24</label>
                </div>
                {/*<div*/}
                {/*    className={!check ? 'radio-element col-18' : isValid?.isPaymentValid ? 'radio-element col-18' : 'radio-element col-18 not-valid'}>*/}
                {/*    <input*/}
                {/*        checked={order.payment.method === 'PayPal'}*/}
                {/*        type="radio"*/}
                {/*        name="paymentGroup"*/}
                {/*        id="PayPal"*/}
                {/*        data-id="PayPal"*/}
                {/*        onChange={handleChange}*/}
                {/*    />*/}
                {/*    <label*/}
                {/*        className="radio-label"*/}
                {/*        htmlFor="PayPal">*/}
                {/*        PayPal</label>*/}
                {/*</div>*/}
            </>}
        </div>
    );
};

export default PaymentSection;