import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {format, parseISO} from "date-fns";
import {useDeleteOrderMutation, useGetOrdersQuery, useUpdateOrderMutation} from "../../Orders/orderApiSlice";
import CustomSelect from "../../../components/Reusable/CustomSelect";
import RemoveButton from "../../../components/Reusable/RemoveButton";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminOrderRow = ({id, number, setError}) => {
    const [display, setDisplay] = useState(false)
    const {CONSTANTS: {ORDER_STATUS_LIST}} = useConfig()
    const [selected, setSelected] = useState({
        status: {
            name: "",
            id: ""
        }
    })
    const {order} = useGetOrdersQuery("orderList", {
        selectFromResult: ({data}) => ({
            order: data?.entities[id]
        }),
    })

    const [deleteOrder, {
        isError,
        error
    }] = useDeleteOrderMutation();

    const [updateOrder, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdateOrderMutation()

    useEffect(() => {
        if (order && selected.status.name && selected.status.name !== order.status) {
            setDisplay(true)
        }
    }, [selected]);

    useEffect(() => {
        if (isUpdateSuccess) setDisplay(false)
    }, [isUpdateSuccess]);

    useEffect(() => {
        if (order || isUpdateError) {
            const status = ORDER_STATUS_LIST.find(el => el.name === order.status) || ""
            setSelected({status})
        }
    }, [order, isUpdateError]);

    useEffect(() => {
        if (isError) setError(getErrorMessage(error));
        if (isUpdateError) setError(getErrorMessage(updateError));
    }, [error, isError, updateError, isUpdateError]);

    const onRemoveOrderClicked = () => {
        try {
            deleteOrder(id)
        } catch (err) {
            console.log(err?.data?.message || err?.message || err?.error || 'An error occurred');
        }
    }

    const onUpdateOrderClicked = async () => {
        try {
            await updateOrder({
                id,
                client: order.client,
                status: selected.status.name
            }).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }

    let result = null
    if (order) {

        const {createdAt, products} = order
        const orderDate = format(parseISO(createdAt), 'dd.MM.yyyy')
        const orderTime = format(parseISO(createdAt), 'HH:mm:ss')
        const orderedProducts = products.map(el =>
            <div className="ordered-product" key={el._id}>
                <span>
                    {el.name} - <span>{el.size}</span>
                </span>
                <span>
                    <span>Ilość: {el.quantity}</span>
                </span>
                <span>{el.color}</span>
            </div>)


        result = (
            <div className="admin__panel__section__list admin__orders__list ">

                <div className={`admin__panel__section__list-item col-1 ${number % 2 ? "even" : "odd"}`}>
                    {order?.number}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {order?.payment?.total}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    <div className="order-time">
                        <span>{orderDate} r.</span>
                        <span>godz: {orderTime}</span>
                    </div>
                </div>
                <div className={`admin__panel__section__list-item col-5 ${number % 2 ? "even" : "odd"}`}>
                    {orderedProducts}
                </div>
                <div
                    className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"} status`}>
                    <CustomSelect
                        placeholder="status"
                        className={`status-select ${number % 2 ? "even" : "odd"}`}
                        selected={selected.status}
                        setSelected={setSelected}
                        attribute="status"
                        list={ORDER_STATUS_LIST}/>
                    <div className={display ? "popup__confirmation" : "popup__confirmation-hide"}>
                        <p className="popup__confirmation-text">
                            Czy chcesz zmienić status tego zamówienia na: <span
                            className="new-status"> {selected.status.name}</span>?
                        </p>
                        <div className="popup__confirmation-buttons">
                            <button
                                className="popup__confirmation-button yellow-button"
                                onClick={() => setDisplay(false)}
                            >Anuluj
                            </button>
                            <button
                                className="popup__confirmation-button yellow-button"
                                onClick={onUpdateOrderClicked}
                            > Zmień
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`admin__panel__section__list-item admin__orders__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    <Link
                        className="list-item-link"
                        to={`/admin/orders/order?id=${id}`}>DANE</Link>
                    <Link
                        className="list-item-link"
                        to={`/admin/orders/order/products?id=${id}`}>PRODUKTY</Link>
                    <RemoveButton
                        onRemove={onRemoveOrderClicked}
                        text={"Czy na pewno chcesz trwale usunąć to zamówienie?"}
                        disabled={order.status !== "pending"}
                    />
                </div>
            </div>
        );
    }
    return result
};

export default AdminOrderRow;