import React from 'react';
import {useGetProductsQuery} from "../../Products/producsApiSlice";
import {Link} from "react-router-dom";
import useConfig from "../../../hooks/useConfig";

const SummaryProduct = ({item}) => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const {id} = item
    const {product} = useGetProductsQuery("productList", {
        selectFromResult: ({data}) => ({
            product: data?.entities[id]
        })
    });
    if (!product) return null;

    const path = `${SERVER_ADDRESS}/img/${product?.id}/${product?.pictures[0]}`;
    return (
        <div className='row cart__product'>
            <div className="col-8">
                <Link className={'cart__product-link'}
                      to={product.kind === 'Karta podarunkowa' ? '/shop/gift_card' : `/shop/${product?.name}`}>
                    <img className='cart__product-image'
                         src={path}
                         alt={product?.name}/>
                    <span className='quantity'>{item.quantity}</span>
                </Link>
            </div>
            <div className={`cart__product-info-content col-10`}>
                <p className='cart__product-kind'> {product?.kind} | {item.color}</p>
                <h2 className='cart__product-name'>{product?.name}</h2>
                <p className='cart__product-size'>{item.size}</p>
                <p className='cart__product-price'>cena</p>
                <p className='cart__product__price-tag'>{item.price} PLN</p>
            </div>
        </div>
    );
};

export default SummaryProduct;