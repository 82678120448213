import React from 'react';
import {Outlet} from "react-router-dom";
import AdminHeader from "./AdminHeader";

const Layout = () => {
    return (
        <>
            <AdminHeader/>
            <div className="admin__panel container">
                <Outlet/>
            </div>
        </>
    );
};

export default Layout;