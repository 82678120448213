import React from 'react';
import AdminEditMarkdown from "../Markdown/AdminEditMarkdown";

const AdminEditPaymentInfo = () => {
    return <AdminEditMarkdown
        title="Płatności"
        navigateAfter="/consts_and_shipping"
        fileName="payment.md"
        className=""
    />;
};

export default AdminEditPaymentInfo;