import {useEffect, useState} from 'react';
import {useGetClientQuery} from "../../clientApiSlice";
import useAuth from "../../../../hooks/useAuth";
import ClientPanelHeader from "../ClientPanelHeader";
import {Link} from "react-router-dom";
import ErrorPopup from "../../../../components/Reusable/ErrorPopup";
import {ReactComponent as Close} from '../../../../images/close.svg';
import {getErrorMessage} from "../../../../utils/getErrorMessage";

const ClientEdit = () => {
    const {email} = useAuth()
    const [err, setErr] = useState(null);
    const {
        data: client,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientQuery(email, {
        skip: !email,
    });

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    return (
        <>
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <Link
                className="close-button"
                to={'/account'}
            ><Close/></Link>
            <ClientPanelHeader client={client}/>
            <div className="row client__edit">
                <div className="col-18 col-lg-8 client__panel__content client__edit-buttons">
                    <Link
                        className="link-button col-18"
                        to="shipping">
                        TWOJE DANE
                    </Link>
                    <Link
                        className="link-button col-18"
                        to="billing">
                        DANE ROZLICZENIOWE
                    </Link>
                    <Link
                        className="link-button col-18"
                        to="change_password">
                        ZMIEŃ HASŁO
                    </Link>
                    <Link
                        className="link-button col-18"
                        to="remove_account"
                    >USUŃ KONTO
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ClientEdit;