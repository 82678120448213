import {useSelector} from "react-redux";
import {selectAll} from "../features/Auth/authSlice";
import {jwtDecode} from "jwt-decode";

const useAuth = () => {
    const {token} = useSelector(selectAll)

    if (token) {
        const decoded = jwtDecode(token)
        const {email, id, isAdmin} = decoded.UserInfo
        return {
            isLoggedIn: true,
            email,
            id,
            isAdmin
        }
    }

    return {
        isLoggedIn: false,
        email: null,
        id: null,
        // roles: [],
        isAdmin: false,
    }
};

export default useAuth;