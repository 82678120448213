import React from 'react';
import ClientPanelOrderCard from "./ClientPanelOrderCard";

const ClientOrders = ({orders}) => {
    const ordersList = orders?.length
        ? orders.map(order => <ClientPanelOrderCard key={order._id} order={order}/>).reverse()
        : <p>Nie masz jeszcze zamówień</p>
    return (
        <div className="col-18 col-lg-8 client__panel__content">
            <h3 className="client__panel__content-title">ZAMÓWIENIA</h3>
            <div className="client__panel__content-orders">
                {ordersList}
            </div>
        </div>
    );
};

export default ClientOrders;