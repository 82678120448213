import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "../../app/api/apiSlice";
import {logoutAndReset} from "../Auth/authSlice";
import {setClintCart} from "../Cart/cartSlice";
import {verifyClientCart} from "../../utils/verifyClientCart";
import {productsApiSlice} from "../Products/producsApiSlice";
import {jwtDecode} from "jwt-decode";

const clientsAdapter = createEntityAdapter({});

const initialState = clientsAdapter.getInitialState();

export const clientApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClient: builder.query({
            query: (email) => ({
                url: `/api/clients/${email}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            // Additional logic to handle refresh
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    const {_id: id, cart} = data;

                    // Verify cart quantities after client cart is loaded
                    const productsResult = await dispatch(productsApiSlice.endpoints.getProducts.initiate());
                    const products = productsResult.data ? productsResult.data.entities : {};

                    //If cart is populated check if products are available
                    if (cart?.totalCartItems) return verifyClientCart(id, cart, products, dispatch);

                    dispatch(setClintCart({id, cart}));

                } catch (err) {
                    console.log(err.error?.data?.message || err.error || err);
                }
            },
            transformResponse: responseData => {
                responseData.id = responseData._id;
                return responseData;
            },
            providesTags: (result, error, arg) => [{type: 'Client', id: result?.id}]
        }),

        confirmClient: builder.query({
            query: (token) => ({
                url: '/api/clients/confirm',
                method: 'POST',
                body: {token},
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
        }),
        resetPassword: builder.mutation({
            query: (email) => ({
                url: '/api/clients/reset',
                method: 'POST',
                body: email,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
        }),
        confirmResetPassword: builder.mutation({
            query: (data) => ({
                url: '/api/clients/reset/confirm',
                method: 'POST',
                body: {...data},
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
        }),

        addNewClient: builder.mutation({
            query: (initialClientData) => ({
                url: '/api/clients',
                method: 'POST',
                body: {
                    ...initialClientData
                }
            }),
            invalidatesTags: [{type: 'Client', id: 'LIST'}]
        }),

        updateClient: builder.mutation({
            query: (initialClientData) => ({
                url: '/api/clients/',
                method: 'PATCH',
                body: {
                    ...initialClientData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Client', id: arg.id},
            ],
        }),
        deleteClient: builder.mutation({
            query: (id) => ({
                url: '/api/clients',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Client', id: arg.id}
            ],
            // Additional logic to handle logout
            async onQueryStarted(arg, {dispatch, getState, queryFulfilled}) {

                try {
                    // Check if admin is deleting client account
                    const {token} = getState().auth
                    const decoded = jwtDecode(token)
                    const {id: loggedInID} = decoded.UserInfo
                    if (loggedInID !== arg) return

                    // If client is deleting his account
                    await queryFulfilled;
                    dispatch(logoutAndReset());

                    // Reset the API state after a short delay
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                        dispatch(clientApiSlice.util.resetApiState())
                    }, 1000);
                } catch (err) {
                    console.log(err.error?.data?.message);
                }
            }
        }),
        getClients: builder.query({
            query: () => ({
                url: '/api/admin/clients',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            transformResponse: responseData => {
                const loadedClients = responseData.map(client => {
                    client.id = client._id;
                    return client;
                });
                return clientsAdapter.setAll(initialState, loadedClients);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Client', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Client', id}))
                    ];
                } else return [{type: 'Client', id: 'LIST'}];
            }
        }),
        adminUpdateClient: builder.mutation({
            query: (initialClientData) => ({
                url: '/api/admin/clients',
                method: 'PATCH',
                body: {
                    ...initialClientData
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                }
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Client', id: arg.id},
            ],
        }),
    })
});

export const {
    useGetClientsQuery,
    useGetClientQuery,
    useConfirmClientQuery,
    useResetPasswordMutation,
    useConfirmResetPasswordMutation,
    useAddNewClientMutation,
    useUpdateClientMutation,
    useAdminUpdateClientMutation,
    useDeleteClientMutation
} = clientApiSlice;

// returns the query object
export const selectClientsResult = clientApiSlice.endpoints.getClients.select();

// creates memoized selector
const selectClientsData = createSelector(
    selectClientsResult,
    clientsResult => clientsResult.data // normalized state object with ids & entities
);

export const {
    selectAll: selectAllClients,
    selectById: selectClientById,
    selectIds: selectClientIds
    // pass in a selector that returns the clients slice of state
} = clientsAdapter.getSelectors(state => selectClientsData(state) ?? initialState);
