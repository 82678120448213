import {ReactComponent as Logo} from "../../images/BeBrave_logo.svg";

const Error = ({error}) => {
    console.log(error);
    const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
    return (
        <div className="loading">
            <Logo/>
            <h1>Ups... coś poszło nie tak</h1>
            <p>{errorMessage}</p>
            <p>{error.status}</p>
        </div>
    );
};

export default Error;