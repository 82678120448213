import {configureStore} from '@reduxjs/toolkit';
import cartReducer from '../features/Cart/cartSlice';
import appReducer from './appSlice';
import authReducer from '../features/Auth/authSlice';
import {apiSlice} from "./api/apiSlice";
import {setupListeners} from "@reduxjs/toolkit/query";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        cart: cartReducer,
        app: appReducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore actions with these paths
                ignoredActions: [
                    'api/executeMutation/fulfilled',
                    'api/executeMutation/rejected',
                    'api/executeQuery/fulfilled',
                    'api/executeQuery/rejected',
                ],
                // Ignore these paths in the state
                ignoredPaths: [
                    /^api\.queries\.getImages.*$/,
                    `meta.baseQueryMeta.request`,
                ], // Customize the path as needed
                // Optionally, ignore the check for all non-serializable values
                ignoredActionPaths: [/^payload\.[0-5]$/], // Ignore the payloads with non-serializable values
            },
        }).concat(apiSlice.middleware),
})

setupListeners(store.dispatch)
export default store