import useAuth from "./useAuth";
import useLocalStorage from "./useLocalStorage";
import {useGetClientQuery} from "../features/Clients/clientApiSlice";

export const useFormData = () => {
    const {id, email} = useAuth();
    const [storageFormData] = useLocalStorage('orderFormData', null);
    // Fetch client data using the email from useAuth
    const {
        data: client,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetClientQuery(email, {
        skip: !email // Skip the query if email is empty
    });

    // Default form data structure
    const defaultData = {
        client: id || 'unregistered',
        clientType: id ? 'Client' : 'unregistered',
        email: "",
        subscribe: false,
        country: {id: 21, name: "Poland", code: "PL", language: "pl"},
        name: "",
        surname: "",
        street: "",
        notes: "",
        postCode: "",
        city: "",
        phone: "",
        products: [],
        remember: false,
        shipment: "",
        invoice: false,
        payment: {
            method: "Przelewy24",
            discount: {
                code: "",
                amount: 0,
                type: "",
                name: "",
            },
            discountAmount: 0,
            products: 0,
            giftCards: 0,
            shipment: 0,
            total: 0,
            totalBeforeDiscount: 0
        },

        billingAddress: {
            theSame: true,
            email: "",
            subscribe: false,
            country: {id: 21, name: "Poland", code: "PL", language: "pl"},
            name: "",
            surname: "",
            street: "",
            notes: "",
            postCode: "",
            city: "",
            phone: "",
        }
    };

    if (isLoading) {
        return {isLoading: true, formData: null}
    }

    if (isSuccess) {

        const {name, surname, email, phone, addresses} = client;
        const {street, postCode, city, country, billingAddress} = addresses;
        const formData = {
            ...defaultData,
            name,
            surname,
            email,
            phone,
            street,
            postCode,
            city,
            billingAddress
        }
        return {isLoading: false, formData}
    }

    // If there's form data in local storage and user is not logged in, use it
    if (!id && storageFormData) {
        return {isLoading: false, formData: storageFormData};
    }
    // Otherwise, return the default form data
    return {isLoading: false, formData: defaultData};
};
