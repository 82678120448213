const InnerSection = ({children}) => {
    return (
        <section className='inner-section'>
            <div className='row'>
                <div className='col-18 col-md-13 white-card'>
                    {children}
                </div>
            </div>
        </section>
    );
};

export default InnerSection;