const ShipmentSection = ({check, isValid, order, setOrder}) => {
    const isVisible = isValid.data

    const handleChange = (e) => {
        setOrder(prev => ({
            ...prev,
            shipment: e.target.id
        }))
    }

    return (
        <div className="form-section">
            <h2 className="form-subtitle">Metoda wysyłki</h2>
            {!isVisible && <div
                className={!check ? 'form-element' : isValid?.isShipmentValid ? 'form-element' : 'form-element not-valid'}
                tabIndex={0}
            >
                <span>Wprowadź dane adresowe, aby wybrać metodę wysyłki.</span>
                <span className={"select_decor"}></span>

            </div>}
            {isVisible && <>
                <div
                    className={!check ? 'radio-element col-18' : isValid?.isShipmentValid ? 'radio-element col-18' : 'radio-element col-18 not-valid'}>
                    <input
                        checked={order.shipment === 'InPost-paczkomat'}
                        type="radio"
                        name="shipmentGroup"
                        id="InPost-paczkomat"
                        tabIndex="0"
                        onChange={handleChange}
                    />
                    <label
                        className="radio-label"
                        htmlFor="InPost-paczkomat">
                        InPost Paczkomat</label>
                </div>
                <div
                    className={!check ? 'radio-element col-18' : isValid?.isShipmentValid ? 'radio-element col-18' : 'radio-element col-18 not-valid'}>
                    <input
                        checked={order.shipment === 'InPost-kurier'}
                        type="radio"
                        name="shipmentGroup"
                        id="InPost-kurier"
                        tabIndex="0"
                        onChange={handleChange}
                    />
                    <label
                        className="radio-label"
                        htmlFor="InPost-kurier">
                        InPost Kurier</label>
                </div>
                {/*<div*/}
                {/*    className={!check ? 'radio-element col-18' : isValid?.isShipmentValid ? 'radio-element col-18' : 'radio-element col-18 not-valid'}>*/}
                {/*    <input*/}
                {/*        checked={order.shipment === 'Orlen-paczka'}*/}
                {/*        type="radio"*/}
                {/*        name="shipmentGroup"*/}
                {/*        id="Orlen-paczka"*/}
                {/*        onChange={handleChange}*/}
                {/*    />*/}
                {/*    <label*/}
                {/*        className="radio-label"*/}
                {/*        htmlFor="Orlen-paczka">*/}
                {/*        Orlen Paczka</label>*/}
                {/*</div>*/}
            </>}
        </div>
    )
}

export default ShipmentSection