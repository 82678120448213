import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";
import {useGetMarkdownQuery} from "../../app/configApiSlice";
import DOMPurify from "dompurify";
import {marked} from "marked";
import React, {useEffect, useState} from "react";
import ErrorPopup from "../../components/Reusable/ErrorPopup";

const Rules = () => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMarkdownQuery('rules.md');

    const [err, setErr] = useState()

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';

            setErr(errorMessage);
        }
    }, [error, isError]);

    const clearError = () => {
        setErr(null);
    };

    let content
    if (isLoading) content = <h1>Loading</h1>
    if (isSuccess && data) content =
        <div className="md-body"
             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(data.content))}}/>

    return (
        <>
            <InnerSection>
                {err && <ErrorPopup message={err} onClose={clearError}/>}
                <div className="rules__content">
                    <h1 className="rules__content-title">Regulamin Sklepu Internetowego BeBrave</h1>
                    {content}
                </div>
            </InnerSection>
            <NewsLetter/>
        </>
    );
};

export default Rules;