import {ReactComponent as LogoWhite} from "../../images/BeBrave_logo.svg";
import {ReactComponent as ArrowDown} from "../../images/arrowDown.svg";
import useConfig from "../../hooks/useConfig";

const Landing = () => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    return (
        <section className='landing'>
            <div className="landing__hero">
                <img
                    className="landing__hero-img"
                    src={`${SERVER_ADDRESS}/img/hero.png`}
                    alt="Hero"
                />
                <a className="landing__hero-arrow" href="#choose">
                    <ArrowDown/>
                </a>
            </div>
            <LogoWhite className="landing__logo"/>
        </section>
    );
};

export default Landing;