import React, {useEffect, useState} from 'react';
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import useQuery from "../../../hooks/useQuery";
import {Link, useNavigate} from "react-router-dom";
import useGetLocations from "../../../hooks/useGetLocations";
import {
    useDeleteDiscountMutation,
    useGetDiscountsQuery,
    useUpdateDiscountMutation
} from "../../Discounts/discountApiSlice";
import PanelSectionInput from "../AdminPanelSection/PanelSectionInput";
import PanelSectionSelect from "../AdminPanelSection/PanelSectionSelect";
import PanelSectionCheckBox from "../AdminPanelSection/PanelSectionCheckBox";
import {adminValidateDiscount} from "../../../utils/adminValidateDiscount";
import useConfig from "../../../hooks/useConfig";

const AdminEditDiscount = () => {
    const [formError, setFormError] = useState()
    const [err, setErr] = useState()
    const [discount, setDiscount] = useState(null)
    const [check, setCheck] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()
    const discountId = useQuery('id');
    const CONFIG = useConfig()
    const locations = useGetLocations()
    locations.pop()

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetDiscountsQuery("discountsList", {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities[discountId],
            ...other
        })
    })

    const [updateDiscount, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdateDiscountMutation()

    const [deleteDiscount, {
        data: deleteData,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
        isError: isDeleteError,
        error: deleteError
    }] = useDeleteDiscountMutation();

    useEffect(() => {
        if (isSuccess && data) {
            const type = CONFIG.CONSTANTS.DISCOUNT_CODE_TYPES.find(el => el.name === data.type)
            const discountData = {...data, type}
            setDiscount(discountData)
            setIsValid(adminValidateDiscount(discountData, CONFIG));
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
            setErr(errorMessage);
        }
        if (isDeleteError) {
            const errorMessage = deleteError?.data?.message || deleteError?.message || deleteError?.error || 'An error occurred';
            setErr(errorMessage);
        }
        if (isUpdateError) {
            const errorMessage = updateError?.data?.message || updateError?.error || 'An error occurred';
            setErr(errorMessage);
        }
    }, [error, isError, deleteError, isDeleteError, updateError, isUpdateError]);

    useEffect(() => {
        if (isUpdateSuccess) navigate("/admin/discounts")
        if (isDeleteSuccess) navigate("/admin/discounts")
    }, [updateResult, isUpdateSuccess, isDeleteSuccess]);

    useEffect(() => {
        if (discount) {
            setFormError(null);
            setIsValid(adminValidateDiscount(discount, CONFIG));
        }
    }, [discount]);

    const clearError = () => {
        setErr(null);
    };

    const onDeleteDiscountClicked = async () => {
        try {
            await deleteDiscount(discount.id).unwrap()
        } catch (err) {
            console.log(err?.data?.message || err?.message || err?.error || 'An error occurred');
        }
    }

    const onSaveDiscountClicked = async (e) => {
        e.preventDefault()
        setCheck(true)
        if (!isValid.all) {
            setFormError('Dane są niepoprawne lub niekompletne.');
            console.log('Discount data incomplete');
            return
        }


        try {
            await updateDiscount({
                ...discount,
                type: discount.type.name
            }).unwrap()
        } catch (err) {
            const errorMessage = err?.data?.message || err?.message || err?.error || 'An error occurred';
            setErr(errorMessage)
        }
    }

    const setInput = (e) => {
        const {value, dataset: {id}} = e.target;
        setDiscount(prev => ({...prev, [id]: id === "amount" ? Number(value) : value}))
    }

    const setCheckBox = (e) => {
        const {checked, dataset: {id}} = e.target;
        setDiscount(prev => ({...prev, [id]: checked}))
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista kodów"}
    </Link>)

    if (isLoading) return <h1>Loading...</h1>
    if (!discount) return null;
    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <div className="admin__panel__section__buttons">
                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                <div className="admin__panel__section__buttons-group">
                    <button
                        className="admin__panel__section__buttons-button red-button"
                        onClick={onDeleteDiscountClicked}
                    >{isDeleteLoading ? "Loading..." : "Usuń"}
                    </button>
                    <button
                        className=" admin__panel__section__buttons-button yellow-button"
                        onClick={onSaveDiscountClicked}
                    >{isUpdateLoading ? "Loading..." : "Zapisz"}
                    </button>
                </div>

            </div>
            <form className="admin__panel__section__from edit__client">
                <PanelSectionInput
                    isValid={isValid.code}
                    check={check}
                    value={discount.code}
                    type="text"
                    setInput={setInput}
                    title="KOD"
                    name="code"
                    placeholder="Kod"
                />
                <PanelSectionCheckBox
                    checked={discount.reusable}
                    title="WIELOKROTNY"
                    name="reusable"
                    label={discount.reusable ? "wielokrotny" : "jednorazowy"}
                    setChecked={setCheckBox}
                />
                <PanelSectionSelect
                    isValid={isValid?.type}
                    check={check}
                    title="RODZAJ"
                    placeholder="Wybierz rodzaj"
                    attribute="type"
                    selected={discount.type}
                    setState={setDiscount}
                    list={CONFIG.CONSTANTS.DISCOUNT_CODE_TYPES}
                />
                <PanelSectionInput
                    isValid={isValid.amount}
                    check={check}
                    value={discount.amount}
                    type="number"
                    setInput={setInput}
                    title="WARTOŚĆ"
                    name="amount"
                    placeholder="wartość"
                />


            </form>
        </section>
    )
};

export default AdminEditDiscount;