import React from 'react';

const SaveButton = ({className, type, isLoading, errorMessage, text, isLoadingText}) => {
    return (
        <div className="button-group">
            <span className='error-message'>{errorMessage}</span>
            <button
                className={isLoading ? `disabled ${className}` : className}
                type={type}
                disabled={isLoading}
            >{isLoading ? `${isLoadingText}` : `${text}`}
            </button>
        </div>
    );
};

export default SaveButton;