import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {logoutAndReset, setCredentials} from "../../features/Auth/authSlice";

// Construct the base URL
const url = new URL(window.location.href);
const baseUrl = process.env.NODE_ENV === 'development'
    ? `${url.protocol}//${url.hostname}:3500`
    : `${url.protocol}//${url.hostname}`

const baseQueryWithConditionalPayload = async (args, api, extraOptions) => {

    const baseQuery = fetchBaseQuery({
        baseUrl,
        credentials: "include",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.token;
            const token2 = process.env.REACT_APP_API_TOKEN;

            headers.set('x-custom-token', token2);
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    });

    const defaultPayload = {RTK_TOKEN: process.env.REACT_APP_RTK_TOKEN};
    const shouldAttachPayload = args.method !== 'GET'
        && args.method !== 'HEAD'
        && !!args.method
        && args.url.startsWith('/api');
    const body = shouldAttachPayload
        ? args.body
            ? {...defaultPayload, ...args.body}
            : defaultPayload
        : args.body;

    const prepareArgs = body
        ? {...args, body}
        : args

    return baseQuery(prepareArgs, api, extraOptions);
};

const baseQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await baseQueryWithConditionalPayload(args, api, extraOptions)
    if (result?.error?.status === 403) {
        console.log('Sending refresh token');
        // send refresh token to get new access token
        const refreshResult = await baseQueryWithConditionalPayload({
            ...args,
            url: '/api/auth/refresh'
        }, api, extraOptions)

        if (refreshResult?.data) {
            // store the new token
            const {accessToken} = refreshResult.data;
            api.dispatch(setCredentials({accessToken}))

            // retry original query with new access token
            result = await baseQueryWithConditionalPayload(args, api, extraOptions)
        } else if (refreshResult?.error) {
            if (refreshResult?.error?.status === 401) {
                console.log("No refresh token logging out...");
                api.dispatch(logoutAndReset())
            }
        }
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Product', 'Order', 'Client', 'Subscriber', 'Discount'],
    endpoints: builder => ({})
})