export const adminValidateDiscount = (discount, CONFIG) => {
    const {CONSTANTS: {DISCOUNT_CODE_TYPES}} = CONFIG
    const validateOption = (option, list) => list.some(el => el.name === option);
    let isValid = {
        amount: typeof discount.amount === "number" && discount.amount > 0,
        code: typeof discount.code === "string" && discount.code !== "",
        type: validateOption(discount.type.name, DISCOUNT_CODE_TYPES),
    }
    let all = Object.values(isValid).every(el => el === true)
    return {...isValid, all}
}