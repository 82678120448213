import {Link, useNavigate} from "react-router-dom";
import {useGetProductsQuery} from "../producsApiSlice";
import React from "react";
import useConfig from "../../../hooks/useConfig";

const ProductCard = ({productId}) => {
    const {CONSTANTS: {SERVER_ADDRESS}} = useConfig()
    const navigate = useNavigate()

    const {product} = useGetProductsQuery("productList", {
        selectFromResult: ({data}) => ({
            product: data?.entities[productId]
        })
    })

    if (product) {
        const {attribute} = product
        const isGiftCard = product.kind === "Karta podarunkowa"
        const {name, price, pictures, kind, promotion: {isPromotion, basePrice}} = product
        const path = `${SERVER_ADDRESS}/img/${productId}/${pictures[0]}`
        const result = (
            <div className='col-16 col-md-8 col-hd-6 choose__content__card'>
                <div className='choose__content__card-header'>
                    {/*<BlackHeart/>*/}
                    <p className="product__attribute">
                        {!!attribute && <span className="product__attribute-value">{attribute}</span>}
                    </p>
                </div>
                <Link
                    to={isGiftCard
                        ? `/shop/gift_card`
                        : `/shop/${name}`}
                    className='choose__content__card-link'>
                    <img
                        className={isGiftCard ? 'card-picture gift-card' : 'card-picture'}
                        src={path}
                        alt={`${kind} ${name}`}
                    /></Link>

                <div className='choose__content__card-footer'>
                    <div className="col-8">
                        <p className='product-name'>{name}</p>
                        {isGiftCard ? null : <p className='product-price'>{price} PLN
                            {isPromotion
                                ? <span className="base-price">{basePrice} PLN</span>
                                : null
                            }</p>}
                    </div>
                    <button
                        className="card-button col-10"
                        onClick={() => navigate(isGiftCard ? `/shop/gift_card` : `/shop/${name}`)}
                    >DODAJ DO KOSZYKA
                    </button>
                </div>
            </div>
        );
        return result
    }
};

export default ProductCard;