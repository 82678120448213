import {createSlice} from '@reduxjs/toolkit'
import {apiSlice} from "../../app/api/apiSlice";


const authSlice = createSlice({
    name: 'auth',
    initialState: {token: null},
    reducers: {
        setCredentials: (state, action) => {
            const {accessToken} = action.payload
            state.token = accessToken
        },

        logOut: (state, action) => {
            localStorage.setItem("persist", JSON.stringify(false))
            state.token = null
        }
    }
})


// Thunk to log out and reset API state
export const logoutAndReset = () => (dispatch) => {
    dispatch(logOut());
    dispatch(apiSlice.util.resetApiState()); // Reset API state
};

export const {
    setCredentials,
    logOut,
} = authSlice.actions
export default authSlice.reducer
export const selectCurrentToken = (state) => state.auth.token
export const selectAll = (state) => state.auth