import {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import useGetLocations from "../../../hooks/useGetLocations";
import {adminValidateDiscount} from "../../../utils/adminValidateDiscount";
import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import PanelSectionInput from "../AdminPanelSection/PanelSectionInput";
import PanelSectionCheckBox from "../AdminPanelSection/PanelSectionCheckBox";
import PanelSectionSelect from "../AdminPanelSection/PanelSectionSelect";
import {useAddNewDiscountMutation} from "../../Discounts/discountApiSlice";
import useConfig from "../../../hooks/useConfig";

const AdminAddDiscount = () => {
    const [formError, setFormError] = useState()
    const [err, setErr] = useState()
    const [discount, setDiscount] = useState({
        order: "Admin",
        orderType: "Admin",
        active: false,
        amount: 0,
        code: "",
        reusable: false,
        type: {id: 1, name: "procent"},
    })
    const [check, setCheck] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const {CONSTANTS: {DISCOUNT_CODE_TYPES}} = useConfig()
    const navigate = useNavigate()
    const CONFIG = useConfig()

    const locations = useGetLocations()
    locations.pop()

    const [addNewDiscount, {
        data,
        isLoading,
        isError,
        error,
        isSuccess
    }] = useAddNewDiscountMutation()

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
            setErr(errorMessage);
        }

    }, [error, isError]);

    useEffect(() => {
        if (isSuccess) navigate("/admin/discounts")
    }, [data, isSuccess]);

    useEffect(() => {
        setFormError(null);
        setIsValid(adminValidateDiscount(discount, CONFIG));
    }, [discount]);

    const clearError = () => {
        setErr(null);
    };

    const onSaveDiscountClicked = async (e) => {
        e.preventDefault()
        setCheck(true)
        if (!isValid.all) {
            setFormError('Dane są niepoprawne lub niekompletne.');
            console.log('Discount data incomplete');
            return
        }

        try {
            await addNewDiscount({
                ...discount,
                type: discount.type.name,
                name: discount.type.name === "karta" ? "Karta rabatowa" : "Kod rabatowy",
            }).unwrap()
        } catch (err) {
            const errorMessage = err?.data?.message || err?.message || err?.error || 'An error occurred';
            setErr(errorMessage)
        }
    }

    const setInput = (e) => {
        const {value, dataset: {id}} = e.target;
        setDiscount(prev => ({...prev, [id]: id === "amount" ? Number(value) : value}))
    }

    const setCheckBox = (e) => {
        const {checked, dataset: {id}} = e.target;
        setDiscount(prev => ({...prev, [id]: checked}))
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista kodów"}
    </Link>)


    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <div className="admin__panel__section__buttons">
                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                >
                <button
                    className=" admin__panel__section__buttons-button yellow-button"
                    onClick={onSaveDiscountClicked}
                >{isLoading ? "Loading..." : "Zapisz"}
                </button>

            </div>
            <form className="admin__panel__section__from edit__client">
                <PanelSectionInput
                    isValid={isValid?.code}
                    check={check}
                    value={discount.code}
                    type="text"
                    setInput={setInput}
                    title="KOD"
                    name="code"
                    placeholder="Kod"
                />
                <PanelSectionCheckBox
                    checked={discount.reusable}
                    title="WIELOKROTNY"
                    name="reusable"
                    label={discount.reusable ? "wielokrotny" : "jednorazowy"}
                    setChecked={setCheckBox}
                />
                <PanelSectionSelect
                    isValid={isValid?.type}
                    check={check}
                    title="RODZAJ"
                    placeholder="Wybierz rodzaj"
                    attribute="type"
                    selected={discount.type}
                    setState={setDiscount}
                    list={DISCOUNT_CODE_TYPES}
                />
                <PanelSectionInput
                    isValid={isValid?.amount}
                    check={check}
                    value={discount.amount}
                    type="number"
                    setInput={setInput}
                    title="WARTOŚĆ"
                    name="amount"
                    placeholder="wartość"
                />
            </form>
        </section>
    )
};

export default AdminAddDiscount;