import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useGetDiscountsQuery, useUpdateDiscountMutation} from "../../Discounts/discountApiSlice";
import CheckBox from "../../../components/Reusable/CheckBox";

const AdminDiscountRow = ({id, number, setError}) => {
    const [display, setDisplay] = useState(false)
    const {discount} = useGetDiscountsQuery("discountsList", {
        selectFromResult: ({data}) => ({
            discount: data?.entities[id]
        }),
    })

    const [updateDiscount, {
        isError,
        error
    }] = useUpdateDiscountMutation();

    useEffect(() => {
        if (isError) {
            const errorMessage = error?.data?.message || error?.message || error?.error || 'An error occurred';
            setError(errorMessage);
        }
    }, [error, isError]);

    const onChangeDisplay = async () => {
        setDisplay(false)
        if (!discount.amount) {
            setError("Nie można aktywować kodu o wartości 0")
            return
        }
        const discountData = {
            id: id,
            active: !discount.active,
            status: "new"
        }
        try {
            await updateDiscount(discountData).unwrap()
        } catch (err) {
            console.log(err?.data?.message || err?.message || err?.error || 'An error occurred')
        }
    }

    let result = null
    if (discount) {
        result = (
            <div className="admin__panel__section__list admin__discounts__list ">
                <div className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    <CheckBox
                        className="admin__panel__section__list-checkbox"
                        checked={discount.active || false}
                        setChecked={() => setDisplay(true)}
                    />
                    {discount?.active
                        ? <span className="acive">Aktywny</span>
                        : <span className="inacive">Nieaktywny</span>}
                    <div className={display ? "popup__confirmation" : "popup__confirmation-hide"}>
                        <p className="popup__confirmation-text">
                            {discount.active
                                ? `Czy na pewno chcesz zdezaktywować kod rabatowy ${discount.code}?`
                                : `Czy na pewno chcesz aktywować kod rabatowy ${discount.code}?`}
                        </p>
                        <div className="popup__confirmation-buttons">
                            <button
                                className="popup__confirmation-button yellow-button"
                                onClick={() => setDisplay(false)}
                            >Anuluj
                            </button>
                            <button
                                className="popup__confirmation-button yellow-button"
                                onClick={onChangeDisplay}
                            > OK
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {discount?.code}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    {discount?.amount}
                </div>
                <div className={`admin__panel__section__list-item col-4 ${number % 2 ? "even" : "odd"}`}>
                    {discount?.type}
                </div>
                <div className={`admin__panel__section__list-item col-3 ${number % 2 ? "even" : "odd"}`}>
                    {discount?.reusable ? "wielokrotny" : "jednorazowy"}
                </div>
                <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                    <Link
                        className="list-item-link"
                        to={`/admin/discounts/discount?id=${id}`}>EDYTUJ</Link>
                </div>
            </div>
        );
    }
    return result
};

export default AdminDiscountRow;