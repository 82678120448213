import {useGetConfigQuery} from "../configApiSlice";
import {Outlet} from "react-router-dom";
import Loading from "../../features/LoadingPage/Loading";
import Error from "../../features/ErrorPage/Error";

const PrefetchConfig = () => {

    // Fetch the config data
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetConfigQuery('configData'); // 'configData' must match the cache key

    // Handle loading and error states for config data
    if (isLoading) {
        return <Loading/>; // Render a loading state until config is fetched
    }

    if (isError) {

        return <Error error={error}/>; // Render an
    }

    if (data && isSuccess) return <Outlet/>; // Only render child routes after config is loaded
}

export default PrefetchConfig