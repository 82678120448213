import ErrorPopup from "../../../components/Reusable/ErrorPopup";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    useDeleteProductMutation,
    useGetAllProductsQuery,
    useUpdateProductMutation
} from "../../Products/producsApiSlice";
import useQuery from "../../../hooks/useQuery";
import PanelSectionInput from "../AdminPanelSection/PanelSectionInput";
import PanelSectionSelect from "../AdminPanelSection/PanelSectionSelect";
import AdminEditProductAddColor from "./AdminEditProductAddColor";
import PanelSectionTextarea from "../AdminPanelSection/PanelSectionTextarea";
import PanelSectionPhotoEdit from "../AdminPanelSection/PanelSectionPhotoEdit";
import {convertFilesToBase64} from "../../../utils/convertFilesToBase64";
import {validateProductData} from "../../../utils/validateProductData";
import useGetLocations from "../../../hooks/useGetLocations";
import useConfig from "../../../hooks/useConfig";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import AdminEditPromotion from "./AdminEditPromotion";

const AdminEditProduct = () => {
    const [formError, setFormError] = useState()
    const [err, setErr] = useState()
    const productId = useQuery('id');
    const [product, setProduct] = useState(null)
    const [check, setCheck] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()
    const CONFIG = useConfig()
    const locations = useGetLocations()
    locations.pop()

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetAllProductsQuery('adminproductsList', {
        selectFromResult: ({data, ...other}) => ({
            data: data?.entities[productId],
            ...other
        }),
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [updateProduct, {
        data: updateResult,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess
    }] = useUpdateProductMutation()

    const [deleteProduct, {
        data: deleteResult,
        isLoading: isDeleteLoading,
        isError: isDeleteError,
        error: deleteError,
        isSuccess: isDeleteSuccess
    }] = useDeleteProductMutation()

    useEffect(() => {
        if (isUpdateSuccess || isDeleteSuccess) navigate("/admin/products")
    }, [updateResult, isUpdateSuccess, deleteResult, isDeleteSuccess]);

    useEffect(() => {
        if (product) {
            setFormError(null);
            setIsValid(validateProductData(product, CONFIG));
        }
    }, [product]);

    useEffect(() => {
        if (isSuccess && data) {
            const kind = CONFIG.CONSTANTS.PRODUCT_KIND.find(el => el.name === data.kind) || ""
            const attribute = CONFIG.CONSTANTS.PRODUCT_ATTRIBUTES.find(el => el.name === data.attribute) || ""
            setProduct({
                ...data,
                kind,
                attribute,
                selectedFiles: [],
            })
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (isError) setErr(getErrorMessage(error));
        if (isUpdateError) setErr(getErrorMessage(updateError));
        if (isDeleteError) setErr(getErrorMessage(deleteError));
    }, [error, isError, updateError, isUpdateError, deleteError, isDeleteError]);

    const clearError = () => {
        setErr(null);
    };

    const setInput = (e) => {
        let {value, dataset: {id}} = e.target;
        if (e.target.type === "number") value = Number(value)
        setProduct(prev => ({...prev, [id]: value}))
    }

    const onSaveProductClicked = async (e) => {
        e.preventDefault()
        // Check if form is valid
        setCheck(true)
        if (!isValid?.all) {
            console.log(isValid);
            setFormError('Dane produktu nie są kompletne.');
            console.log('Order data incomplete');
            return
        }
        const files = product.selectedFiles.filter(el => el !== null)
        const pictures = files.map((file, i) => file.name);
        const base64Files = await convertFilesToBase64(files)

        const updatedProduct = {
            ...product,
            pictures,
            attribute: product.attribute.name === "Brak" ? "" : product.attribute.name,
            kind: product.kind.name,
            selectedFiles: base64Files
        }
        try {
            await updateProduct(updatedProduct).unwrap()
        } catch (err) {
            console.log(getErrorMessage(err));
        }
    }

    const onDeleteProductClicked = async () => {
        try {
            await deleteProduct(productId)
        } catch (err) {
            console.log(getErrorMessage(err));
        }
    }

    const links = locations.map((el, i) => <Link
        key={i}
        className="admin__panel__section__buttons-button go-back blue-button"
        to={el.url}>
        {el.name === "admin" ? "Panel administracyjny" : "Lista produktów"}
    </Link>)

    if (isLoading) return <h1>Loading...</h1>
    if (!product) return null;
    return (
        <section className=" admin__panel__section  col-18 col-lg-16">
            {err && <ErrorPopup message={err} onClose={clearError}/>}
            <h1>Produkt: {product.name}</h1>
            <div className="admin__panel__section__buttons">

                <div className="admin__panel__section__buttons-group">
                    {links}
                </div>
                <span className='error-message'>{formError}</span>
                <div className="admin__panel__section__buttons-group">
                    <button
                        className="admin__panel__section__buttons-button red-button"
                        onClick={onDeleteProductClicked}
                    >{isDeleteLoading ? "Loading..." : "Usuń"}
                    </button>
                    <button
                        className="admin__panel__section__buttons-button yellow-button"
                        onClick={onSaveProductClicked}
                    >{isUpdateLoading ? "Loading..." : "Zapisz"}
                    </button>
                </div>
            </div>
            <form className="admin__panel__section__from edit__product">
                <PanelSectionInput
                    isValid={isValid?.name}
                    check={check}
                    value={product.name}
                    type="text"
                    setInput={setInput}
                    title="NAZWA"
                    name="name"
                    placeholder="Nazwa"
                />
                <PanelSectionSelect
                    isValid={isValid?.kind}
                    check={check}
                    title="RODZAJ"
                    placeholder="Brak"
                    attribute="kind"
                    selected={product.kind}
                    setState={setProduct}
                    list={CONFIG.CONSTANTS.PRODUCT_KIND}
                />
                <PanelSectionSelect
                    isValid={isValid?.attribute}
                    check={check}
                    title="WYŚWIETL"
                    placeholder="Brak"
                    attribute="attribute"
                    selected={product.attribute}
                    setState={setProduct}
                    list={CONFIG.CONSTANTS.PRODUCT_ATTRIBUTES}
                />
                <PanelSectionInput
                    isValid={isValid?.price}
                    check={check}
                    value={product.price}
                    type="number"
                    setInput={setInput}
                    title="CENA"
                    name="price"
                    placeholder="Cena"
                />
                <AdminEditPromotion
                    check={check}
                    isValid={isValid}
                    product={product}
                    setProduct={setProduct}
                />

                <AdminEditProductAddColor
                    isValid={isValid}
                    check={check}
                    state={product}
                    setInput={setProduct}
                />
                <PanelSectionPhotoEdit
                    isValid={isValid}
                    check={check}
                    state={product}
                    setSelectedFiles={setProduct}
                    setFormError={setFormError}
                    setErr={setErr}
                />
                <PanelSectionTextarea
                    isValid={isValid?.description}
                    check={check}
                    value={product.description}
                    setInput={setInput}
                    title="OPIS"
                    name="description"
                    placeholder="Dodaj opis"
                />
                <PanelSectionTextarea
                    isValid={isValid?.material}
                    check={check}
                    value={product.material}
                    setInput={setInput}
                    title="SKłAD I PIELĘGNACJA"
                    name="material"
                    placeholder="Dodaj opis składi i pielęgnacji"
                />
                <PanelSectionTextarea
                    isValid={isValid}
                    check={check}
                    value={product.sizeChartInfo}
                    setInput={setInput}
                    title="TABELA ROZMIARÓW"
                    name="sizeChartInfo"
                    placeholder="Dodaj opis tabeli rozmiarów"
                />
            </form>
        </section>
    );
};

export default AdminEditProduct;