import React from 'react';
import AdminEditMarkdown from "../Markdown/AdminEditMarkdown";

const AdminEditGiftCardRules = () => {
    return <AdminEditMarkdown
        fileName="gift_card_rules.md"
        navigateAfter="/gift_card_rules"
        title="Edycja Regulaminu karty prezentowej"
        className="gitCard__rules__content-body"
    />
};

export default AdminEditGiftCardRules;