import React from 'react';
import CheckBox from "../../../components/Reusable/CheckBox";

const PanelSectionCheckBox = ({checked, setChecked, title, label, className, name}) => {
    return (
        <div className="admin__panel__section__from__section ">
            <span
                className="admin__panel__section__from__section-title col-18 col-md-4 col-lg-3 col-hd-2">{title}</span>
            <div className="admin__panel__section__from__section-inputs col-18 col-md-14 col-lg-15 col-hd-16">
                <CheckBox
                    name={name}
                    setChecked={setChecked}
                    checked={checked}
                    label={label}
                    className={className}
                />
            </div>
        </div>
    );
};

export default PanelSectionCheckBox;