const validateOrderData = (order, CONFIG) => {
    let {REG_EX, CONSTANTS: {COUNTRIES, PAYMENT_METHODS, SHIPMENT_OPTIONS}} = CONFIG
    const validateField = (field, regex) => regex.test(field);
    const validateOption = (option, list) => list.some(el => el.name === option);
    const {
        email,
        subscribe,
        country,
        name,
        surname,
        street,
        notes,
        postCode,
        city,
        phone,
        shipment,
        payment: {method},
        billingAddress,
    } = order;

    const COUNTRY = country.name.replace(" ", "_").toUpperCase()
    const BILLING_COUNTRY = billingAddress.country.name.replace(" ", "_").toUpperCase()

    const isEmailValid = validateField(email, REG_EX.EMAIL);
    const isSubscribeValid = typeof subscribe === 'boolean';
    const isCountryValid = validateOption(country.name, COUNTRIES);
    const isNameValid = validateField(name, REG_EX.NAME);
    const isSurnameValid = validateField(surname, REG_EX.NAME);
    const isStreetValid = validateField(street, REG_EX.STREET_NAME);
    const isNotesValid = validateField(notes, REG_EX.CLIENT_NOTES);
    const isPostCodeValid = validateField(postCode, REG_EX[COUNTRY].POST_CODE);
    const isCityValid = validateField(city, REG_EX.EU_CITY_NAME);
    const isPhoneValid = validateField(phone, REG_EX[COUNTRY].PHONE_NUMBER);
    const isShipmentValid = validateOption(shipment, SHIPMENT_OPTIONS);
    const isPaymentValid = validateOption(method, PAYMENT_METHODS);

    const data = [
        isEmailValid,
        isSubscribeValid,
        isCountryValid,
        isNameValid,
        isSurnameValid,
        isStreetValid,
        isNotesValid,
        isPostCodeValid,
        isCityValid,
        isPhoneValid
    ].every(Boolean);

    const all = [
        data,
        isEmailValid,
        isSubscribeValid,
        isCountryValid,
        isNameValid,
        isSurnameValid,
        isStreetValid,
        isNotesValid,
        isPostCodeValid,
        isCityValid,
        isPhoneValid,
        isShipmentValid,
        isPaymentValid
    ].every(Boolean);

    if (!billingAddress.theSame) {
        const {
            country: billingCountry,
            name: billingName,
            surname: billingSurname,
            street: billingStreet,
            notes: billingNotes,
            postCode: billingPostCode,
            city: billingCity,
            phone: billingPhone,
        } = billingAddress;

        const isBillingCountryValid = validateOption(billingCountry.name, COUNTRIES);
        const isBillingNameValid = validateField(billingName, REG_EX.NAME);
        const isBillingSurnameValid = validateField(billingSurname, REG_EX.NAME);
        const isBillingStreetValid = validateField(billingStreet, REG_EX.STREET_NAME);
        const isBillingNotesValid = validateField(billingNotes, REG_EX.CLIENT_NOTES);
        const isBillingPostCodeValid = validateField(billingPostCode, REG_EX[BILLING_COUNTRY].POST_CODE);
        const isBillingCityValid = validateField(billingCity, REG_EX.EU_CITY_NAME);
        const isBillingPhoneValid = validateField(billingPhone, REG_EX[BILLING_COUNTRY].PHONE_NUMBER);

        const billingAddressAll = [
            isEmailValid,
            isSubscribeValid,
            isCountryValid,
            isNameValid,
            isSurnameValid,
            isStreetValid,
            isNotesValid,
            isPostCodeValid,
            isCityValid,
            isPhoneValid,
            isShipmentValid,
            isPaymentValid,
            isBillingCountryValid,
            isBillingNameValid,
            isBillingSurnameValid,
            isBillingStreetValid,
            isBillingNotesValid,
            isBillingPostCodeValid,
            isBillingCityValid,
            isBillingPhoneValid
        ].every(Boolean);


        const result = {
            all: billingAddressAll,
            data,
            billingAddress: {
                isCountryValid: isBillingCountryValid,
                isNameValid: isBillingNameValid,
                isNotesValid: isBillingNotesValid,
                isSurnameValid: isBillingSurnameValid,
                isStreetValid: isBillingStreetValid,
                isPostCodeValid: isBillingPostCodeValid,
                isCityValid: isBillingCityValid,
                isPhoneValid: isBillingPhoneValid,
            },
            isEmailValid,
            isSubscribeValid,
            isCountryValid,
            isNameValid,
            isNotesValid,
            isSurnameValid,
            isStreetValid,
            isPostCodeValid,
            isCityValid,
            isPhoneValid,
            isShipmentValid,
            isPaymentValid
        };
        return result
    } else {
        const result = {
            all,
            data,
            billingAddress: "theSame",
            isEmailValid,
            isSubscribeValid,
            isCountryValid,
            isNameValid,
            isNotesValid,
            isSurnameValid,
            isStreetValid,
            isPostCodeValid,
            isCityValid,
            isPhoneValid,
            isShipmentValid,
            isPaymentValid
        };
        return result
    }
};

export default validateOrderData;
