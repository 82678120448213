import React from 'react';
import AdminEditMarkdown from "../Markdown/AdminEditMarkdown";

const AdminEditShopRules = () => {
    return <AdminEditMarkdown
        fileName="rules.md"
        navigateAfter="/rules"
        title="Edycja Regulaminu"
        className="rules__content-body"
    />
};

export default AdminEditShopRules;