export const productObject = {
    display: false,
    attribute: {id: 0, name: ""},
    selectedFiles: [],
    name: "",
    kind: {id: 0, name: ""},
    quantities: {
        list: [],
        colors: [],
    },
    description: "",
    price: 0,
    promotion: {
        isPromotion: false,
        promotionAmount: 0,
        basePrice: 0
    },
    amounts: [],
    sizeChartInfo: "",
    material: ""
}