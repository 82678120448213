import React, {useEffect, useState} from 'react';
import {useGetAllProductsQuery, useUpdateProductMutation} from "../../Products/producsApiSlice";
import {Link} from "react-router-dom";
import CheckBox from "../../../components/Reusable/CheckBox";
import {getErrorMessage} from "../../../utils/getErrorMessage";

const AdminProductRow = ({id, number, setError}) => {
    const [display, setDisplay] = useState(false)

    const {product, isSuccess, isError, error} = useGetAllProductsQuery('adminproductsList', {
        selectFromResult: ({data}) => ({
            product: data?.entities[id]
        })
    })

    const [updateProduct, {
        isError: isUpdateProductError,
        error: updateProductError
    }] = useUpdateProductMutation();

    useEffect(() => {
        if (isUpdateProductError) setError(getErrorMessage(updateProductError));
    }, [updateProductError, isUpdateProductError]);

    const onChangeDisplay = async () => {
        setDisplay(false)
        const productData = {
            id: id,
            display: !product.display
        }
        try {
            await updateProduct(productData).unwrap()
        } catch (err) {
            console.error(getErrorMessage(err));
        }
    }
    const quantities = product.quantities.list.map((color) => {
        const productInColor = product.quantities.colors
            .filter(el => el.color === color)

        const sizes = productInColor.map((el, i) => {
                return <span className={`quantities__list__color__sizes__size ${el.size}`} key={el._id}>
                    <span className="quantities__list__color__sizes__size-size ${}"
                          key={`${i}-${el.size}`}>{el.size}: </span>
                    <span className="quantities__list__sizes__size-quantity"
                          key={`${i}-${el.quantity}`}>{el.quantity} </span>
                </span>
            }
        )
        return (<div className="quantities__list" key={color}>
            <div className="quantities__list__color">
                <p className="quantities__list__color-title">{color}</p>
                <div className="quantities__list__color__sizes">{sizes} </div>
            </div>

        </div>)
    })


    return (
        <div className="admin__panel__section__list admin__products__list ">
            <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                <CheckBox
                    className="admin__panel__section__list-checkbox"
                    checked={product.display || false}
                    setChecked={() => setDisplay(true)}
                />
                <div className={display ? "popup__confirmation" : "popup__confirmation-hide"}>
                    <p className="popup__confirmation-text">
                        {product.display
                            ? "Czy na pewno chcesz zdjąć produkt ze strony?"
                            : "Czy na pewno chcesz pokazać produkt na stronie?"}
                    </p>
                    <div className="popup__confirmation-buttons">
                        <button
                            className="popup__confirmation-button yellow-button"
                            onClick={() => setDisplay(false)}
                        >Anuluj
                        </button>
                        <button
                            className="popup__confirmation-button yellow-button"
                            onClick={onChangeDisplay}
                        > OK
                        </button>
                    </div>
                </div>

            </div>
            <div className={`admin__panel__section__list-item col-5 ${number % 2 ? "even" : "odd"}`}>
                {product?.name}
            </div>
            <div className={`admin__panel__section__list-item product__kind col-4 ${number % 2 ? "even" : "odd"}`}>
                {product?.kind}
            </div>
            <div className={`admin__panel__section__list-item product__kind col-2 ${number % 2 ? "even" : "odd"}`}>
                {product?.price}
                {product.lowestPriceInThirtyDays.lowestPrice !== product.price
                    ? `/${product.lowestPriceInThirtyDays.lowestPrice}`
                    : null}
            </div>
            <div
                className={`admin__panel__section__list-item col-3  ${number % 2 ? "even" : "odd"}`}>
                <div className="quantities">
                    {quantities}
                </div>
            </div>
            <div className={`admin__panel__section__list-item col-2 ${number % 2 ? "even" : "odd"}`}>
                <Link
                    className="list-item-link"
                    to={`/admin/products/product?id=${id}`}>EDYTUJ</Link>
            </div>
        </div>
    );
};

export default AdminProductRow;