export const getPaymentAmounts = (order, SHIPMENT_OPTIONS) => {
    const {products: orderProducts, payment: {discount: {type, amount}}, shipment} = order
    const shipmentOption = SHIPMENT_OPTIONS.find(el => el.name === shipment)
    const shipmentCost = shipmentOption ? shipmentOption.price : 0;
    const {products, giftCards, eligibleAmount, isEligible, vatTax} = orderProducts.reduce((acc, el) => {
        return el.name === "Karta podarunkowa"
            ? {...acc, giftCards: acc.giftCards + (el.price * el.quantity)}
            : {
                ...acc,
                products: acc.products + (el.price * el.quantity),
                vatTax: acc.vatTax + (el.price - (el.price / 1.23)),
                eligibleAmount: el.isPromotion
                    ? acc.eligibleAmount
                    : acc.eligibleAmount + (el.price * el.quantity),
                isEligible: el.isPromotion
                    ? acc.isEligible
                    : acc.isEligible = true
            };
    }, {products: 0, giftCards: 0, eligibleAmount: 0, vatTax: 0, isEligible: false});

    let discountAmount = 0
    let total = 0

    if (type === "procent") {
        discountAmount = eligibleAmount * amount
        total = products - discountAmount + shipmentCost + giftCards
    } else if (type === "kwota" && isEligible && eligibleAmount > amount) {
        discountAmount = amount
        total = products - discountAmount + shipmentCost + giftCards
    } else if (type === "karta" && products > amount) {
        discountAmount = amount
        total = products - discountAmount + shipmentCost + giftCards
    } else if (type === "kwota" && isEligible && eligibleAmount < amount) {
        discountAmount = amount
        total = products - eligibleAmount + shipmentCost + giftCards
    } else if (type === "karta" && products < amount) {
        discountAmount = amount
        total = shipmentCost + giftCards
    } else {
        total = products + shipmentCost + giftCards
    }

    const totalBeforeDiscount = products + shipmentCost
    return {
        ...order,
        payment: {
            ...order.payment,
            shipment: shipmentCost,
            discountAmount,
            products,
            giftCards,
            total,
            totalBeforeDiscount,
            isEligible,
            eligibleAmount,
            vatTax: Number(vatTax.toFixed(2))
        }
    }
}