import React, {useState} from 'react';
import {ReactComponent as VisibleEye} from '../../images/Icon feather-eye.svg';
import {ReactComponent as NotVisibleEye} from '../../images/Icon feather-eye-off.svg';


const PasswordInput = ({
                           handlePwdInput,
                           isValid = true,
                           check = false,
                           name = 'password',
                           dataId = 'password',
                           placeholder = 'Hasło'
                       }) => {
    const [isVisible, setIsVisible] = useState(false)
    const icon = isVisible ? <VisibleEye/> : <NotVisibleEye/>
    const type = isVisible ? 'text' : 'password'
    return (
        <div className="password__input__group">
            <span
                className="password__input__group-icon"
                onClick={() => setIsVisible(prev => !prev)}>{icon}</span>
            <input
                className={!check ? 'custom-input col-18' : isValid ? 'custom-input col-18' : 'custom-input col-18 not-valid'}
                type={type}
                name={name}
                data-id={dataId}
                placeholder={placeholder}
                onChange={handlePwdInput}
                autoComplete="off"
            />
        </div>
    );
};

export default PasswordInput;