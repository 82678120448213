import InnerSection from "../../components/Reusable/InnerSection";
import NewsLetter from "../Newsletter/NewsLetter";

const PaymentInfo = () => {
    return (
        <>
            <InnerSection>
                <h1>Metody płatności</h1>
            </InnerSection>
            <NewsLetter/>
        </>
    );
};

export default PaymentInfo;